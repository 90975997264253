<div class="document-library" role="none">
    <div class="back-btn-wrapper" role="none">
        <div class="header-left" role="none">
            <button class="back-btn" (click)="goToDashboard()" role="button" aria-label="back to benefit lists">
                <img class="backspace-icon" title="{{'bb.needsAssessment.review.back' | translate}}" alt="back-arrow"
                    src="../../../assets/images/back-arrow.svg" />
            </button>
            <span class="title-style" role="heading" aria-level="1" aria-label="Document Library">Document
                Library</span>
        </div>
    </div>
    <div class="scroll-content">
        <div *ngFor="let doc of docs;let indx = index" class="doc">
            <div class="type">
                <img  width="48px" height="64px" src="../../../../../assets/images/icon_PDF.svg" alt="benefits_builder"
                    role="img" />
            </div>
            <p class="title" (click)="openDownloadToastr(doc)">{{doc.documentType}}</p>
            <div class="ver">
                <p  class="ver-text">Doc ID: <span class="ver-ed">{{doc.documentID}}</span></p>
            </div>
            <p class="date">Date: {{doc.documentDate | date: 'yyyy-MM-dd'}}</p>
        </div>
    
    </div>
</div>