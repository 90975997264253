import { Injectable, Injector } from '@angular/core';
import { BaseClientService } from './../../../core/services/base-client.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { NeedsAssessmentSharedService } from '../../needs-assessment/services/needs-assessment-shared.service';
import { CookieService } from 'ngx-cookie-service';
import { urlType } from './../../../core/models/urlType';
import { TasksInfoService } from 'src/app/core/services/tasks-info.service';
import { benefitQualifyingQuestion } from 'src/app/core/models/constants';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from 'src/app/core/services/language-translation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';

export interface Benefit {
  dynamicPointsSupport?: any;
  tierValues?: Array<TierValues>;
  tierTranslatedValues?: Array<TierValues>;
  singleSelectMultiplicity?: boolean;
  isCashoutSpecialized?: any;
  iconDisplayName?: string;
  iconLink?: string;
  reference: string;
  tempLiving?: boolean;
  productSpecificationReference: string;
  displayName: string;
  description: string;
  category: string;
  points?: number | 'Guaranteed';
  /**amount specific for MMUser */
  amount?: number | 'Guaranteed';
  type?: string; //type for MMUser simple or complex
  selectedbenefitAmount?: number; // selected benefit amount
  selectedbenefitCurrency?: string; // selected benefit currency
  selectedAmount?: number; // Total of selected MMUser benefit amount and added benefit
  hostCurrency?: string; //host currency after selection
  iscurrencyconversion?: boolean; //to check if currency conversion done or not
  selected?: boolean;
  maxMul?: number;
  selectedbenefitCount?: number; // selected benefit count
  selectedCount?: number; // Total of selected benefit count and added benefit
  confirmedCount?: number;
  confirmedDate?: string;
  selectedDate?: string;
  rangeMin?: number; // for cashout min value
  rangeMax?: number; // for cashout max value
  pointValue?: number; // for cashout card point equivalent dollar value
  selectedCashOutPoints?: number;  // for cashout card selected cashout benefit point(temporary).
  selectedCashOutValue?: number;  // for cashout card selected cashout benefit value.

  confirmedCashOutPoints?: number;  // for cashout card confirmed cashout point
  prerequisites?: Array<string>;
  exclusions?: Array<string>;
  inclusions?: Array<string>;
  disable?: boolean; // flag to disable benefit if part of exclusions
  taskInfo?: TaskInfo;
  productId?: string;
  pointsPerIncrement?: number; // for time range increment benefits, points increment value
  rangeIncrementValue?: number; // for time range increment benefits, range value increment value
  rangeIncrementUnit?: string; // for time range increment benefits range units
  selectedIncrementCount?: number; // for time range increment benefits, selected benfit counts
  confirmedIncrementCount?: number; // for time range increment benefits, confirmed benefit benfit counts
  perPointCurrency?: number; // for incremental dollar per point cards
  selectedDollarIncrementCount?: number; // for incremental dollar per point cards, selected benefit counts 
  confirmedDollarIncrementCount?: number; // for incremental dollar per point cards, confirmed benefit counts

  icon?: string;
  hasNoPreRequisite?: boolean; // flag to disable benefit when pre requisites are not added
  consultantOnlyCard?: boolean; // flag to determine if it can be configured by consultant only
  qualifyingInput?: 1 | 2; // qualifying question to be displayed
  activated?: boolean; // flag to determine if the consultant card benefit is activated for transferee
  tierConfigs?: Array<TierConfig>;
  advCashOutPointValue?: number; // cashout total value selected
  advCashOutSelectedPoints?: number // cashout selected points
  confirmedAdvCashOutPoints?: number // cashout confirmed points
  confirmedAdvCashOutValue?: number; // cashout total value confirmed
  confirmedAdvCashOutCurr?: string; // cashout currency 
  addCount?: number; //Multiplicity card selected 
  isSelectBenefitForCB?: any; // for co-browsing
  /**MMUser budget structure to be finalised*/
  budget?: {
    total?: {
      estimatedAmount?: {
        amount: number,
        currency: string,
        displayedAmount?: string;
      },
      actualAmount?: {
        amount: number,
        currency: string,
      }
    },
    budgetBreakUp?: {
      estimatedAmount?: Array<Expense>,
      actualAmount?: Array<Expense>
    }
  };
  productMonetaryValue?:{
    amount?:number,
    currency?:string
  }
  parameters?: Array<Question>;
  pointsPerSelection?: number; // cash out points per selection 
  legacyProduct?: { //MMU leagacy product mapping
    productName?: string,
    subProductName?: string
  };
  actualAmount?: number;
  assessNeeds?: AssessNeeds;
  housingPreference?: HousingPreference;
  cashOut?: boolean;
  triggeredAccessInfo?:{
    days?: number
    milestone?: string
    milestoneDate?: string
    percentage?: number
  }
  hybridTempLiving?: any; //hybrid 
  coreTimeUnit?: any; //core hybrid time aptr
  coreUnits?: any; //
  hybridDelCount?: any; //temp var for deleting selected instances
  modifications?: any;
  dynamicAmount?: number;
  dynamicCurrency?: string;
  dynamicAvailable?: boolean ;
  derivedValueSources?: Array<string>;
  multiRef?: string;
  tierConfigsSelectedPoints?: number;
  isDEIEnabled? : boolean;
  isSustainabilityEnabled? : boolean;
  currencyCode?: string;
}
export interface Expense {
  displayname: string,
  expense: {
    amount: number,
    currency: string
  }
  activated?: boolean; // flag to determine if the consultant card benefit is activated for transferee
  tierConfigs?: Array<TierConfig>;
  advCashOutPointValue?: number; // cashout total value selected
  advCashOutSelectedPoints?: number // cashout selected points
  confirmedAdvCashOutPoints?: number // cashout confirmed points
  confirmedAdvCashOutValue?: number; // cashout total value confirmed
  confirmedAdvCashOutCurr?: string; // cashout currency 
  pointsPerSelection?: number; // cash out points per selection
  iconDisplayName?: string;
  iconLink?: any;
  tempLiving?: boolean; // flag to identify tempLiving offering
}
export interface Question {
  _id: string,
  displayname: string,
  type: string,
  value?: {
    amount?: number,
    currency?: string
  },
  enum?: Array<string>
}
/**MMUser budget structure to be finalised*/
export interface TierConfig {
  maxSelection: number;
  totalMaxSelectionLimit?: number;
  minRange: number;
  maxRange: number;
  pointValue: number;
}

export interface TierValues{
  displayValue :string;
  value: any;
  isSelected?:boolean
}

export interface BenefitsData {
  availableBenefits?: Array<Benefit>;
  selectedBenefits?: Array<Benefit>;
  confirmedBenefits?: Array<Benefit>;
  points?: number;
  amount?: number;
  /**Get actuals for MMUser integrated in getbenefits api */
  benefitsActualsInfo?: {
    benefitsBreakDowns?: [{
      billingCurrencyAmt :number,
      billingCurrencyCode :number,
      disbursedCurrencyAmt :number,
      disbursedCurrencyCode : string
      hostCurrencyAmt : number,
      hostCurrencyCode: string,
      reference :string
    }],
    totalsInfo?: {
      actualRemaining?: number
      actualSpend?: number
      hostCurrencyAmt?: number
      hostCurrencyCode?: string
    }
  }
}

export interface TaskInfo {
  allTaskComplete?: boolean;
  allHighValueTaskOptOut?: boolean;
  allHighValueTaskComplete?: boolean;
  tasks: Task[];

}
export interface Task {
  displayName: string;
  description?: string;
  reference?: string;
  status?: boolean;
  highValueItemTypeList?: HighValueItemTypeInfo[];
  highValueItems?: HighValueItem[];
  date?: string;
}


export interface HighValueItem {
  id?: string;
  name: string;
  replacementValue: number;
  description: string;
  serialNumber: string;
}

export interface HighValueItemTypeInfo {
  name: string;
  group: string;
}


export interface TaskUpdate {
  reference: string,
  status: boolean
}
export interface PetsInformation {
  name: string,
  type: string,
  breed: string,
  weight: number
}
export interface AssessNeeds {
  accompanyingMembers: Array<any>,
  petsInformation: PetsInformation[],
  departureLocation: string,
  travelHistory: Array<string>, 
  assessNeedsDraft: boolean
  assessNeedsSubmittedDate: any
}

export interface highValue_tempLiving {
  displayName: string,
  id: string
}

  export interface HousingPreference {
    desiredLocation?: {
      city: string,
      state: string,
      country: string
    },
    alternateLocation?: {
      city: string,
      state: string,
      country: string
    },
    destinationOfficeLocation?: {
      city: string,
      state: string,
      country: string
    },
    moveInDate?: string,
    moveOutDate?: string,
    numberBedrooms?: string,
    numberApartments?: any,
    babyCotRequired?: boolean,
    acceptableCommute?: number,
    priorities?: Array<string>,
    prioritiesTranslated?: Array<string>,
    additionalInstructions?: string,
    housingPreferencesDraft?: boolean,
    housingPreferencesSubmittedDate?: string,
    numberBedroomsTranslatedValueList?: Array<string>,
    numberBedroomsValueList?: Array<string>,
    prioritiesValueList?: Array<string>,
    prioritiesTranslatedValueList?: Array<string>,
    accommodationType?: any,
    housekeepingInfo?: any,
    furnished?: boolean,
    kitchenRequired?: boolean,
    parkingInfo?: any,
    numberOfBathroomsValueList?: any,
    numberOfBathrooms?: string,
  }

type BenefitDataTypesAvailableToUpdate = 'availableBenefits' | 'selectedBenefits';
@Injectable({
  providedIn: 'root'
})
export class BenefitService {
  transfereeDetails: any;
  isMMUser: boolean = false;
  amountRedeemed: string;
  browserLanguage: string;
  constructor(
    private readonly baseClientService: BaseClientService,
    private injector: Injector,
    private readonly cookieService: CookieService,
    private readonly needAssessmentSharedSvc: NeedsAssessmentSharedService,
    private readonly toastr: MatSnackBar,
    private readonly tasksService: TasksInfoService,
    public translate: TranslateService,
    private languageTranslationService: LanguageTranslationService,
    private readonly spinner: NgxSpinnerService
  ) { 
    this.browserLanguage = this.languageTranslationService.getSupportedLanguage();
    translate.use(this.browserLanguage );
  }

  /** Keeps all the data related to Benefits */
  public benefitsData = new BehaviorSubject<BenefitsData>(null);
  public benefitsData$ = this.benefitsData.asObservable();

    /** Keeps all the data related to splitted Benefits */
  public splittedBenefitsData = new BehaviorSubject<Benefit[]>(null);

  /** Keeps all the data related to Benefits */
  pendingHighValueBenefitsData = new BehaviorSubject<Benefit[]>([]);

  public defaultCategory: string;  //Before Move
  public categoryNames = [];
  public BenefitColorMapping = [];
  public colors = [];
  public _category = [];
  selectedBenefitCategory = new BehaviorSubject<string>(null);

  /**behaviorSubject for -- total budget reduced to total_budget < confirmed + selected */
  budgetReduced = new BehaviorSubject<any>(null);

  /** Instance of BehaviorSubject of actual cost information*/
  actualCostInformation = new BehaviorSubject<any>(null);
  
  /** Behaviour subject for temp living contact card */
  contactCardInformation = new BehaviorSubject<any>(null);

  /** Instance of BehaviorSubject to store benefits actuals information*/
  benefitsActualsInfo = new BehaviorSubject<any>(null);
  benefitsActualsInfo$ = this.benefitsActualsInfo.asObservable();

  public benefitsDataResponse(response: any) {
    if (response.confirmedBenefits) {
      this.benefitsData.next(response);
    }
  }

  getPendingNonHighValueBenefits(confirmedBenefits: Benefit[]) {
    let pendingNonHvgBenefits: Benefit[] = [];

    if (confirmedBenefits && confirmedBenefits.length > 0) {
      confirmedBenefits.forEach(benefit => {
        if (benefit && benefit.taskInfo && (!benefit.taskInfo.hasOwnProperty('allHighValueTaskComplete') ||
          !benefit.taskInfo.hasOwnProperty('allHighValueTaskOptOut')) &&
          benefit.taskInfo.hasOwnProperty('allTaskComplete')) {
          if (!benefit.taskInfo.allTaskComplete) {
            pendingNonHvgBenefits.push(benefit);
          }
        }
      });
    }
    return pendingNonHvgBenefits;
  }
  /** commented as per MOV360-2446 ticket*/
  // getPendingHighValueBenefits(confirmedBenefits: Benefit[]) {
  //   let pendingHighvalueBenefits: Benefit[] = [];


  //   if (confirmedBenefits && confirmedBenefits.length > 0) {
  //     confirmedBenefits.forEach(benefit => {
  //       if (benefit && benefit.taskInfo &&
  //         (benefit.taskInfo.hasOwnProperty('allHighValueTaskComplete') ||
  //           benefit.taskInfo.hasOwnProperty('allHighValueTaskOptOut'))
  //       ) {
  //         if (!benefit.taskInfo.allHighValueTaskComplete && !benefit.taskInfo.allHighValueTaskOptOut) {
  //           pendingHighvalueBenefits.push(benefit);
  //         }
  //       }
  //     });
  //   }
  //   return pendingHighvalueBenefits;
  // }

  getTempLivingBenefits(confirmedBenefits: Benefit[]) {
    let tempLivingBenefits: Benefit[] = [];
    if (confirmedBenefits && confirmedBenefits.length > 0) {
      confirmedBenefits.forEach(benefit => {
        if (benefit && benefit.tempLiving && (benefit.housingPreference && benefit.housingPreference.housingPreferencesDraft) || (benefit.assessNeeds && benefit.assessNeeds.assessNeedsDraft)) {
          tempLivingBenefits.push(benefit);
        }
      });
    }
    return tempLivingBenefits;
  }

  getBenefits(lang?:string): Observable<BenefitsData> {
    this.transfereeDetails = this.needAssessmentSharedSvc.transfereeNeedsAssessmentDetails.getValue();
    let url = '';

    if (this.transfereeDetails && this.transfereeDetails.hasOwnProperty('budgetDetails')) {
      url = this.languageTranslationService.addURLParamForSupportedLanguage('/v1/benefit?availableBenefits=true&selectedBenefits=true&confirmedBenefits=true&actuals=true', lang);
    } else {
      url = this.languageTranslationService.addURLParamForSupportedLanguage('/v1/benefit?availableBenefits=true&selectedBenefits=true&confirmedBenefits=true',lang);
    }
    this.spinner.show();
    return this.baseClientService
      .getById(url)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
           this.spinner.hide();
          return of(empty);
        })
      );
  }

  getBenefitsData(orderRequestId: string, isConfirm): Observable<any> {
    return this.baseClientService
      .getById(`/v1/benefit/document-data?orderRequestId=${orderRequestId}&isConfirm=${isConfirm}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          const empty = null;
          return of(empty);
        })
      );
  }

  assignBenefitColor(benefit: Benefit) {
    return this.BenefitColorMapping[benefit.category];
  }

  /**
   * Takes a benefit from one source and adds it to the target.
   * @param benefit Benefit being moved.
   * @param source Source list
   * @param target Destination target list.
   */
  updateBenefitsData(benefit: Benefit, source: BenefitDataTypesAvailableToUpdate, target: BenefitDataTypesAvailableToUpdate) {
    if (source === target) {
      return;
    }

    if (target === 'availableBenefits') {
      delete benefit.selectedDate;
    }

    const benefitsData = {
      ...this.benefitsData.getValue()
    };
    // target === 'selectedBenefits' && benefit.hybridTempLiving ? delete benefit.selectedbenefitCount : null;
    if (!benefit.maxMul && !benefit.pointsPerIncrement && !benefit.perPointCurrency && !benefit.pointValue && !benefit.tierConfigs) {
      // Removing benefit from the source list if no multiplicity
      benefitsData[source] = this.removeCard(benefitsData, source, benefit);
      // Adding benefit to target list

      // Requirement for Selected benefits is to add a new benefit to the top of the list..
      if (target === 'selectedBenefits') {
        benefitsData[target].unshift(benefit);
      } else {
        benefitsData[target].push(benefit);
      }

    } else {
      // All available benefits are selected or selected benefits are deleted(source)  -- Remove card
      if ((benefit.maxMul === ((benefit.selectedCount || 0) + (benefit.confirmedCount || 0)) || benefit.selectedCount === 0) && !benefit.singleSelectMultiplicity) {
        benefitsData[source] = this.removeCard(benefitsData, source, benefit);
      } else if (benefit.pointsPerIncrement && ((
        ((benefit.selectedIncrementCount || 0) +
          (benefit.confirmedIncrementCount || 0)) === (benefit.rangeMax / benefit.rangeIncrementValue)
      ) || benefit.selectedIncrementCount === 0)) {
        benefit.hybridTempLiving ? benefitsData[source][benefitsData[source].findIndex(ele => ele.reference == benefit.reference)] = benefit : null;
        benefitsData[source] = this.removeCard(benefitsData, source, benefit);
      } else if (benefit.perPointCurrency &&
        ((benefit.selectedDollarIncrementCount || 0) +
          (benefit.confirmedDollarIncrementCount || 0)) === (benefit.rangeMax / benefit.rangeIncrementValue)) {
            benefitsData[source][benefitsData[source].findIndex(ele => ele.reference == benefit.reference)] = benefit;
            benefitsData[source] = this.removeCard(benefitsData, source, benefit);
      } else if (benefit.tierConfigs &&
        ((benefit.rangeMax === ((benefit.advCashOutSelectedPoints || 0) + (benefit.confirmedAdvCashOutPoints || 0))) ||
          (benefit.selectedCashOutPoints === 0)
        )) {
        benefitsData[source] = this.removeCard(benefitsData, source, benefit);
      }
      else if ((benefit.maxMul)&& benefit.singleSelectMultiplicity) {
        benefitsData[source] = this.removeCard(benefitsData, source, benefit);
      }
      else {
        // update available/selected source card with updated selected count
        benefitsData[source] = this.updateCard(benefitsData, source, benefit);
      }
      // update target available/selected card with selected count
      benefitsData[target] = this.updateCard(benefitsData, target, benefit);
    }

    // Passing new Benefits data to BenefitsData
    setTimeout(() => {
      const newBenefitsData = {
        ...benefitsData
      };

      this.benefitsData.next(newBenefitsData);
    }, 200);

  }

  updateCard(benefits: BenefitsData, type: BenefitDataTypesAvailableToUpdate | 'confirmedBenefits', updatedbenefit): Array<Benefit> {
    const benefitToBeUpdated = benefits[type].filter(b => b.hybridTempLiving ? (b.reference === updatedbenefit.reference && b.pointsPerIncrement) : b.reference === updatedbenefit.reference);
    // If card does not exist add new card
    if (!benefitToBeUpdated || benefitToBeUpdated.length === 0) {
      if (type === 'selectedBenefits') { // Adding Benefit List to the top of the list.. for selected benefits...
        benefits[type].unshift(updatedbenefit);
        return benefits[type];
      } else {
        return benefits[type].concat(updatedbenefit);
      }

    } else { // update existing card
      return benefits[type].map(b => {
        if (b.hybridTempLiving ? b.reference === updatedbenefit.reference && b.pointsPerIncrement : b.reference === updatedbenefit.reference) {
          return updatedbenefit;
        } else {
          return b;
        }
      });
    }
  }

  removeCard(benefits, type, updatedbenefit) {
    if (
      (updatedbenefit.perPointCurrency && updatedbenefit.selectedDollarIncrementalCount)|| 
      (updatedbenefit.pointsPerIncrement && updatedbenefit.selectedIncrementCount > 0 && type == 'selectedBenefits') || 
      (updatedbenefit.hybridTempLiving && updatedbenefit.selectedIncrementCount > 0 && type == 'selectedBenefits') || 
      (updatedbenefit.pointsPerIncrement && updatedbenefit.selectedIncrementCount && type == 'availableBenefits' && updatedbenefit.selectedIncrementCount < (updatedbenefit.rangeMax / updatedbenefit.rangeIncrementValue)) ||
      (updatedbenefit.perPointCurrency && updatedbenefit.selectedDollarIncrementCount && type == 'availableBenefits' && updatedbenefit.selectedDollarIncrementCount < (updatedbenefit.rangeMax / updatedbenefit.rangeIncrementValue)) 
      ) {
      return benefits[type];
    } else {
    return benefits[type].filter(b => b.hybridTempLiving && (b.reference === updatedbenefit.reference)? (b.coreUnits ? b.reference  === updatedbenefit.reference : b.reference !== updatedbenefit.reference && b.pointsPerIncrement): b.reference !== updatedbenefit.reference);
    }
  }


  selectBenefit(benefit: Benefit): Observable<{
    reference: string, productSpecificationReference: string, selectedPoints?: string, selectedAmount?: number, hostCurrency?: string,
    selectedOfferingBudget?: {
      amount?: number,
      currency?: string
    }
  }> {
    benefit = { ...benefit };
    delete benefit.icon;
    delete benefit.hasNoPreRequisite;
    delete benefit.selectedDate;
    delete benefit.budget;
    delete benefit.iconDisplayName;
    delete benefit.iconLink;
    delete benefit.hybridDelCount ;
    delete benefit.triggeredAccessInfo;
    delete benefit.cashOut;
    delete benefit.derivedValueSources;
    delete benefit.isSelectBenefitForCB;
    delete benefit.tierTranslatedValues;
    benefit.multiRef ? delete benefit.multiRef : null;
    delete benefit.tierConfigsSelectedPoints;
    //Remove DEI/sustanability flags
    benefit.hasOwnProperty('isDEIEnabled') ? delete benefit.isDEIEnabled : null;
    benefit.hasOwnProperty('isSustainabilityEnabled') ? delete benefit.isSustainabilityEnabled : null;
    return this.baseClientService
      .post(`/v1/benefit`, benefit)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }

  /**
   * Delete benefit information 
   * @param benefits Array of benefits to be deleted
   */
  deleteBenefits(benefits: Array<Benefit>, deleteAllHybrid?: boolean) {
    this.transfereeDetails = this.needAssessmentSharedSvc.transfereeNeedsAssessmentDetails.getValue();
    if (this.transfereeDetails && this.transfereeDetails.hasOwnProperty('budgetDetails')) {
      this.isMMUser = true;
    } else {
      this.isMMUser = false;
    }
    const benToBeDeleted = JSON.parse(JSON.stringify(benefits)) || [];
    const benefitBody = benToBeDeleted.map(
      ({ reference, productSpecificationReference, points, selectedCount, selectedIncrementCount, selectedDollarIncrementCount, rangeMin,
        rangeIncrementValue, confirmedIncrementCount, hybridTempLiving, selectedbenefitCount }) => {
        if (selectedIncrementCount) {
          if (benToBeDeleted.length === 1) {
            // selectedIncrementCount = ((deleteAllHybrid && hybridTempLiving) || hybridTempLiving) ? selectedIncrementCount : ((selectedIncrementCount <= (rangeMin / rangeIncrementValue)) && !confirmedIncrementCount) ?
            selectedIncrementCount = (deleteAllHybrid === false && hybridTempLiving ) ? selectedbenefitCount : (deleteAllHybrid && hybridTempLiving) ? selectedIncrementCount : ((selectedIncrementCount <= (rangeMin > 0 ?  rangeMin/ rangeIncrementValue : 1)) && !confirmedIncrementCount) ?
              (rangeMin > 0 ?  rangeMin/ rangeIncrementValue : 1) : 1;
          }
          return {
            reference,
            productSpecificationReference,
            points,
            selectedIncrementCount
          };
        }
        if (selectedDollarIncrementCount) {
          if (benToBeDeleted.length === 1) {
            selectedDollarIncrementCount = ((selectedDollarIncrementCount <= (rangeMin > 0 ?  rangeMin/ rangeIncrementValue : 1)) && !confirmedIncrementCount) ?
              (rangeMin > 0 ?  rangeMin/ rangeIncrementValue : 1) : 1;
          }
          return {
            reference,
            productSpecificationReference,
            points,
            selectedDollarIncrementCount
          };
        }
        if (this.isMMUser && benToBeDeleted.length === 1 && !benToBeDeleted[0].maxMul) { // to delete flex offering for MMUser
          return {
            reference,
            productSpecificationReference
          };
        }
        if (benToBeDeleted.length === 1) {
          if (benToBeDeleted[0].singleSelectMultiplicity) {
            selectedCount = benToBeDeleted[0].selectedCount;
          } else {
           selectedCount = 1;
          } 
        } // when multiplicity cards are not deleted in bulk
        if (this.isMMUser && benToBeDeleted.length === 1 && benToBeDeleted[0].maxMul) { // to delete flex offering for MMUser
          selectedCount = 1;
          return {
            reference,
            productSpecificationReference,
            selectedCount
          };
        }
        if (this.isMMUser && benToBeDeleted.length === 1 && !benToBeDeleted[0].maxMul) { // to delete flex offering for MMUser
          return {
            reference,
            productSpecificationReference
          };
        }
        return {
          reference,
          productSpecificationReference,
          points,
          selectedCount
        };
      });
    return this.baseClientService
      // tslint:disable-next-line: max-line-length
      .bulkDelete(`/v1/benefit`, benefitBody)
      .pipe(
        map(r => r),
        catchError(err => {
          console.log('Failed to delete benefit', err);
          const emptyResp = null;
          return of(emptyResp);
        })
      );
  }

  benefitPointValidation(points: number) {
    const toastr = this.injector.get(MatSnackBar);
    const needsAssessmentService = this.injector.get(NeedsAssessmentSharedService);
    const needsAssessment = needsAssessmentService.transfereeNeedsAssessmentDetails.getValue();
    if (needsAssessment.budgetDetails) {
      const totalAmount = needsAssessment.budgetDetails.totalAmount;//this.benefitsData.getValue().amount;
      const newTotalAmount = (needsAssessment.budgetDetails.confirmedAmount || 0) + (needsAssessment.budgetDetails.selectedAmount || 0)
        + points;
      if (newTotalAmount > totalAmount) {
        toastr.open(this.translate.instant('bb.alertMessages.totalAmountMessage'), null, {
          duration: 6000
        });
        return false;
      }
    } else if (needsAssessment.pointDetails) {
      const totalPoints = this.benefitsData.getValue().points;
      const newTotalPoints = (needsAssessment.pointDetails.confirmedPoints || 0) + (needsAssessment.pointDetails.selectedPoints || 0)
        + points;
      if (newTotalPoints > totalPoints) {
        toastr.open(this.translate.instant('bb.alertMessages.totalPointsMessage'), null, {
          duration: 6000
        });
        return false;
      }
    }
    return true;
  }

  /**
   * Split multilicity benefits to array of individual benefits
   */
  splitBenefits(benefits: Array<Benefit>): Array<Benefit> {
    // test
    let splitselectedBenefits = [];
    benefits.forEach((benefit, i) => {
      if (benefit.maxMul) {
        if (benefit.singleSelectMultiplicity) {
          const newInstanceofbenefit = {...benefit}
          splitselectedBenefits.push(newInstanceofbenefit);
        } else {
          // splitselectedBenefits = splitselectedBenefits.concat(new Array(benefit.selectedCount).fill(benefit));

          for (let i=0; i < benefit.selectedCount; i++) {
            const newInstanceofbenefit = {...benefit}
            splitselectedBenefits.push(newInstanceofbenefit);
          }
            
        }
      } else if (benefit.pointsPerIncrement && !benefit.hybridTempLiving) { // for incremental benefits
        // splitselectedBenefits = splitselectedBenefits.concat(new Array(benefit.selectedIncrementCount).fill(benefit));
        for (let i=0; i < benefit.selectedIncrementCount; i++) {
          const newInstanceofbenefit = {...benefit}
          splitselectedBenefits.push(newInstanceofbenefit);
        }
      } else if (benefit.perPointCurrency && !benefit.hybridTempLiving) { // for incremental benefits
        // splitselectedBenefits = splitselectedBenefits.concat(new Array(benefit.selectedDollarIncrementCount).fill(benefit));
        for (let i=0; i < benefit.selectedDollarIncrementCount; i++) {
          const newInstanceofbenefit = {...benefit}
          splitselectedBenefits.push(newInstanceofbenefit);
        }
      } else {
        const newInstanceofbenefit = {...benefit}
        splitselectedBenefits.push(newInstanceofbenefit);
      }

    });
    return splitselectedBenefits;
  }

  addMultiRefOnSplittedBenefits(benefits: Benefit[]) {
    let splittedBenefitsWithMultiRef = [];
    benefits.forEach((benefit, i) => {
      benefit['multiRef'] = benefit.reference + "_" + i;
      splittedBenefitsWithMultiRef.push(benefit);
    });
    return splittedBenefitsWithMultiRef;
  }

  /*** To confirm selected benefits
   * @param benefits Array of selected benefits
   */
  confirmBenefit(benefits: Array<Benefit>): Observable<[{ reference: string, productSpecificationReference: string, status: string }]> {
    const data = {
      'benefits': [...benefits.map(benefit => {
        delete benefit.icon;
        delete benefit.hasNoPreRequisite;
        delete benefit.selectedDate;
        delete benefit.selectedCashOutValue;
        delete benefit.selectedCashOutPoints;
        delete benefit.selectedbenefitCount;
        delete benefit.type; // MMUser
        delete benefit.budget; // MMUser
        delete benefit.parameters; // MMUser
        delete benefit.confirmedDate; //MMUser-multiplicity
        delete benefit.productId;// MMUser-multiplicity
        delete benefit.selectedbenefitAmount;//MMUser-multiplicity
        delete benefit.iscurrencyconversion; //MMUser
        delete benefit.addCount;//MMUser multiplicity
        delete benefit.iconDisplayName;
        delete benefit.iconLink;
        delete benefit.cashOut;
        delete benefit.triggeredAccessInfo;
        delete benefit.derivedValueSources;
        delete benefit.isSelectBenefitForCB;
        delete benefit.tierTranslatedValues;
        benefit.multiRef ? delete benefit.multiRef : null;
        //Remove DEI/sustanability flags
        benefit.hasOwnProperty('isDEIEnabled') ? delete benefit.isDEIEnabled : null;
        benefit.hasOwnProperty('isSustainabilityEnabled') ? delete benefit.isSustainabilityEnabled : null;
        return benefit;
      })]
    };
    return this.baseClientService
      .post(`/v1/benefit/confirm`, data)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }

  /**
   * To disable benefits based on exclusions of selected benefits
   * @param exclusionList Array of benefits to be excluded
   * @param benefits benefit listing
   */
  resetExclusions(exclusionList: Array<string>,InclusionList: Array<string>, benefits) {
    benefits.availableBenefits.map((benefit) => {
      if (InclusionList.length > 0) {
        if(!InclusionList.includes(benefit.reference)) {
           exclusionList = exclusionList.concat(benefit.reference) ;
        }
      }
      exclusionList =  [...new Set(exclusionList)];
      exclusionList.includes(benefit.reference) ? benefit.disable = true : delete benefit.disable;
    });
  }

  /**
   * Iterate through all selected and confirmed benefits to get list of exclusions
   * @param benefits list of all benefits
   */
  getListOfExclusions(benefits) {
    const selectedBenefits = benefits.selectedBenefits.concat(benefits.confirmedBenefits);
    let listOfSelectedExclusions: Array<string> = [];
    let listOfSelectedInclusions: Array<string> = [];
    selectedBenefits.map((b) => {
      (b.exclusions && b.exclusions.length > 0) ? listOfSelectedExclusions = listOfSelectedExclusions.concat(b.exclusions) : null;
      (b.inclusions && b.inclusions.length > 0) ? listOfSelectedInclusions = listOfSelectedInclusions.concat(b.inclusions) : null;
    });
    this.resetExclusions([...new Set(listOfSelectedExclusions)],[...new Set(listOfSelectedInclusions)], benefits);
  }

  getListOfPreRequisites(benefits) {
    const selectedBenefits = benefits.selectedBenefits.concat(benefits.confirmedBenefits);
    benefits.availableBenefits.map((benefit: Benefit) => {
      if (benefit.prerequisites) {
        for (const p of benefit.prerequisites) {
          if (selectedBenefits.filter((benefit) => benefit.reference === p).length > 0) {
            benefit.hasNoPreRequisite = false;
            break; // break from loop of even if one of the pre requisites matches with selected benefit (Or Condition)
          }
          benefit.hasNoPreRequisite = true;
        }
      }
    });
    return benefits;
  }

  /**
   * To get list of selected benefits for which the provided benefit is a prerequisite of
   * @param benefits : array of benefits
   */
  getPreRequisiteDependencies(benefits: Array<Benefit>) {
    let dependencies: Array<Benefit> = [];
    let dependentBenefits = [];
    for (let benefit of benefits) {
      const allBenefits = this.benefitsData.getValue();
      const confirmselectBenefits = allBenefits ? [...allBenefits.selectedBenefits, ...allBenefits.confirmedBenefits] : null;
      if (confirmselectBenefits) {
        let confirmedSelBenefitsRef = confirmselectBenefits.map((val) => val.reference);
        confirmedSelBenefitsRef = confirmedSelBenefitsRef.filter((val) => val !== benefit.reference);
        // get list of all dependent cards
        const allDependentBenefits = allBenefits.selectedBenefits.filter((selBenefit: Benefit) => {
          if (selBenefit.prerequisites && selBenefit.points !== 'Guaranteed') {
            return selBenefit.prerequisites.includes(benefit.reference);
          } else if (selBenefit.prerequisites && selBenefit.amount !== 'Guaranteed') {
            return selBenefit.prerequisites.includes(benefit.reference);
          }
        });
        // get list of all dependent cards with no pre requisite in selected list
        dependentBenefits = dependentBenefits.concat(allDependentBenefits.filter((d) => {
          for (const pre of d.prerequisites) {
            if (confirmedSelBenefitsRef.includes(pre)) {
              return null;
            }
          }
          return d;
        }));
      } else {
        return null;
      }
    }
    if (dependentBenefits.length > 0) {
      dependencies = this.getPreRequisiteDependencies(dependentBenefits);
    }
    dependencies = [...dependentBenefits, ...dependencies];
    return dependencies;
  }

  /**Method to update the task status for benefits pending with tasks */
  updateBenefitTaskStatus(taskUpdate: Array<TaskUpdate>, productId: string): Observable<any> {
    const data = { 'tasks': taskUpdate };
    return this.baseClientService
      .patch(`/v1/benefit/${productId}`, data)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }

  /**
   * Sort the benefits by { Before Move, Shipping, Travel & Transport, After Arrival, Allowance,Ongoing Support }
   * in the exact order with each group alphabetically sorted benefits..
   * @param benefits List of Selected Benefits
   */
  sortBenefitByGroup(benefits: Array<Benefit>): Array<Benefit> {
    let _benefits = [];

    // For core cards
    Object.keys(this._category).map(category => {
      // Get Benefits by Category and sort the list by name;
      const _benefitsByCategory = benefits
        .filter(benefit => benefit.category === category && (benefit.points && benefit.points === 'Guaranteed'))
        .sort((a, b) => a.displayName.localeCompare(b.displayName));

      // Merge the benefits into the source list...
      _benefits = _benefits.concat(_benefitsByCategory.map(benefit => benefit));
    });

    // Merge the flexit benefits into the source list...
    _benefits = _benefits
      .concat(benefits
        .filter(benefit => (benefit.points !== 'Guaranteed'))
        .sort((a, b) => new Date(b.selectedDate).getTime() - new Date(a.selectedDate).getTime()));

    return _benefits;
  }

  /**
   * Sort the benefits by { Before Move, Shipping, Travel & Transport, After Arrival, Allowance,Ongoing Support }
   * in the exact order with each group alphabetically sorted benefits for pdf..
   * @param benefits List of Selected Benefits
   */
   sortBenefitByGroupForPDF(benefits: Array<Benefit>): Array<Benefit> {
    let _benefits = [];

    // For core cards
    Object.keys(this._category).map(category => {
      // Get Benefits by Category and sort the list by name;
      const _benefitsByCategory = benefits
        .filter(benefit => benefit.category === category && (benefit.points && benefit.points === 'Guaranteed'))
        .sort((a, b) => a.displayName.localeCompare(b.displayName));

      // Merge the benefits into the source list...
      _benefits = _benefits.concat(_benefitsByCategory.map(benefit => benefit));
    });

    // Merge the flexit benefits into the source list...
    _benefits = _benefits
      .concat(benefits
        .filter(benefit => (benefit.points !== 'Guaranteed'))
        .sort((a, b) => a.displayName.localeCompare(b.displayName)));

    return _benefits;
  }
  /**
   * To reorder available benefits by consultant only card
   * @param availableBenefits available benefits ordered alphabetically
   */
  reOrderAvailableBenefits(availableBenefits) {
    const activatedConsultantOnlyCards = [];
    const nonActivatedConsultantCards = [];
    availableBenefits = availableBenefits.filter(benefit => {
      if (!benefit.consultantOnlyCard) {
        return benefit;
      } else if (benefit.consultantOnlyCard && benefit.activated) {
        activatedConsultantOnlyCards.push(benefit);
      } else if (benefit.consultantOnlyCard && !benefit.activated) {
        nonActivatedConsultantCards.push(benefit);
      }
    });
    availableBenefits = this.cookieService.get('transferee-context') ?
      [...availableBenefits, ...activatedConsultantOnlyCards, ...nonActivatedConsultantCards] :
      [...availableBenefits, ...activatedConsultantOnlyCards];
    return availableBenefits;

  }
  /**
     * Method for opting out or decline the High value insurance task
     * @param productId 
     */
  optOutHighValueTask(productId: string): Observable<any> {
    const data = {};
    return this.baseClientService
      .patch(`/v1/benefit/${productId}/hvg/optout`, data)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }
  /**
   * To activate/deactivate consultant card
   * @param activateBenefit benefit reference with flag to activate/deactivate
   */
  activateConsultantCard(activateBenefit: { referenceId: string, activated: boolean }) {
    return this.baseClientService
      .put(`/v1/benefit/activate-deactivate`, activateBenefit)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }
  /**
   * Method to add insurance Item
   * @param highValueItem 
   * @param productId 
   */
  addHighValueItem(highValueItem: HighValueItem, productId: string): Observable<{ code: string, message: string, id: string }> {
    return this.baseClientService
      .post(`/v1/benefit/${productId}/hvg/insurance`, highValueItem)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }

  /**
   * Method to edit added high value Item
   * @param highValueItem 
   * @param productId 
   */
  editHighValueItem(highValueItem: HighValueItem, productId: string): Observable<{ code: string, message: string, id: string }> {
    return this.baseClientService
      .put(`/v1/benefit/${productId}/hvg/insurance/${highValueItem.id}`, highValueItem)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }
  /**
   * Method to delete added high value item.
   * @param highValueItemId
   * @param productId
   */
  deleteHighValueItem(highValueItemId: string, productId: string): Observable<{ code: string, message: string }> {
    return this.baseClientService
      .delete(`/v1/benefit/${productId}/hvg/insurance/${highValueItemId}`)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }
  /**
   * Method to submit high value item task
   * @param productId
   */
  submitHighValueItem(productId: string): Observable<{ code: string, message: string }> {
    return this.baseClientService
      .put(`/v1/benefit/${productId}/hvg/insurance/submit`, {})
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }

  getCategoryData(lang: string): Observable<any> {
    let url = this.languageTranslationService.addURLParamForSupportedLanguage('/v1/value-list/?key=ProductCategories',lang);
    return this.baseClientService
      .get<any>(url)
      .pipe(
        map(r => r),
        catchError((err, source) => {
          const empty: any = null;
          return of(empty);
        })
      );
    // return of(categoryData);
  }

  getCategoryDisplayNames(lang: string) {
    this.getCategoryData(lang).subscribe(res => {
      if (res) {
        let categoryDisplayNames;
        let displayNameObject = {};
        categoryDisplayNames = [...res.body.values];
        res.body.values = res.body.values.sort((a, b) => a.order - b.order);
        this.defaultCategory = res.body.values[0].displayName
        res.body.values.forEach((ele, index) => {
          categoryDisplayNames[index] = ele.displayName;
          displayNameObject = {
            en: ele.displayName,
            tr : ele.translatedDisplayName ? ele.translatedDisplayName : undefined
          }
          this.categoryNames.push(displayNameObject);
          this.BenefitColorMapping[ele.displayName] = ele.color.replace(/\s/g, '');
          this.colors.push(ele.color);
          this._category[ele.displayName] = null;
        });
        this.colors.push('#f7f0f069');
        this.colors.push('transparent');
        this.selectedBenefitCategory.next(categoryDisplayNames[0]);
      }
    });
  }
  /**
   * Method to convert currency to host currency for simple flex benefit MMUser
   */
  convertToHostCurrency(value: { amount: number, from: string, to: string }): Observable<{ amount: number, from: string, to: string, convertedValue: number }> {
    return this.baseClientService.get(`/v1/currency/convertCurrency?from=${value.from}&to=${value.to}&amount=${value.amount}`).pipe(
      map(r => r.body), catchError(err => {
        const empty = null;
        console.log('Failed to convert currency: ', err);
        return of(empty);
      })
    );
  }

  getDynamicSupport(originalBenefit:Benefit):Observable<any>{
    let points = originalBenefit.points;
    let reference = originalBenefit.reference;
    return this.baseClientService.get(`/v1/benefit/value-allocation?dynamicPointsSupport=true&points=${points}&reference=${reference}`).pipe(
      map(r => r.body), catchError(err => {
        const empty = null;
        console.log('Failed to Get Amount and  currency: ', err);
        return of(empty);
      })
  //   let value = {
  //     "productMonetaryValue": {
  //         "amount": 20800,
  //         "currency": "USD"
  //     }
  
  // }
    //return of(value);
   );
  }

  getCalculatedCost(originalBenefit: Benefit): Observable<{ budget: { amount: number, currency: string } }> {
    const benefit = Object.assign({}, originalBenefit);
    delete benefit.icon;
    delete benefit.hasNoPreRequisite;
    delete benefit.selectedDate;
    delete benefit.budget;
    delete benefit.iscurrencyconversion;
    delete benefit.selectedbenefitAmount;
    delete benefit.selectedbenefitCurrency;
    delete benefit.maxMul;
    delete benefit.iconLink;
    delete benefit.iconDisplayName;
    delete benefit.disable;
    let reqPayload = {
      benefits: [benefit]
    };
    // pass language if browser language is not default language
    const url = this.languageTranslationService.addURLParamForSupportedLanguage('/v1/benefit/calculate-estimates', this.browserLanguage)
    return this.baseClientService
      .post(`${url}`, reqPayload)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(err.message);
        })
      );

  }
  /**
   * Function to Update the actualCostInformation subject
   * @param data updated actualCostInformation
   */
  updateActualInfo(data: Array<any>) {
    this.actualCostInformation.next(data);
  }

  /**
   * Function to Get actualCostInformation and update Shared-Service
   */
  getActualCostInformation() {
    if (!this.actualCostInformation.getValue()) {
      let fileId: number;
      this.tasksService.taskInformation.subscribe(tasks => {
        if (tasks && tasks.policyCall) {
          fileId = (tasks.policyCall.hasOwnProperty('custFileId') && tasks.policyCall.custFileId !== null) ? tasks.policyCall.custFileId : 0;
          if (fileId && fileId !== undefined) {
            this.getActuals(fileId).subscribe(data => {
              if (data) {
                const productWise = data.body.prodWiseTransactions
                this.updateActualInfo(productWise);
              }
            });
          }
        }
      });
    } else {
      return this.actualCostInformation.getValue();
    }
  }
  getActuals(fileId: any): Observable<any> {
    this.transfereeDetails = this.needAssessmentSharedSvc.transfereeNeedsAssessmentDetails.getValue();
    let orderRequestId = this.transfereeDetails && this.transfereeDetails.orderRequestId;
    const hostCurrency = (this.transfereeDetails && this.transfereeDetails.budgetDetails) ? this.transfereeDetails.budgetDetails.currency : '';
    // orderRequestId = '6160adc396f5510008b264ae';
    return this.baseClientService
      .get<any>(`/v1/transaction/${fileId}?context=managed-move&orderRequestId=${orderRequestId}&currencyAbbr=${hostCurrency}`,
        '', urlType.integrationApi)
      .pipe(
        map(r => r),
        catchError((err, source) => {
          const empty: any = null;
          return of(empty);
        })
      );
  }

  /**
  * Method to add AssessNeeds Items
  * @param assessNeeds
  */
  addAssessNeedsItem(assessNeeds: AssessNeeds, productId): Observable<any> {
    return this.baseClientService
      .put(`/v1/benefit/${productId}/assess-needs`, assessNeeds)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }

  /**
  * Method to add housingPreference Items
  * @param assessNeeds
  * @param productId
  */
  addHousingPreferenceItem(housingPreference: HousingPreference, productId): Observable<any> {
    return this.baseClientService
      .put(`/v1/benefit/${productId}/housing-preference`, housingPreference)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }

  /** get my contacts and notification for temp living */
  getMyContacts(): Observable<any> {
    // return of (contactData);
    return this.baseClientService
    .getById<any>('/v1/benefit/booking-details')
    .pipe(
      map(r => r),
      catchError((err, source) => {
        const empty: any = null;
        return of(empty);
      })
    );
  }
  
  /**method to remove notificatio for my contact
   * temp living
   */
  updateViewStatus(viewStatus: boolean, id): Observable<any> {
    const payload = {
      'viewStatus': viewStatus
    }
    return this.baseClientService
      .put(`/v1/benefit/order/${id}/view-status`, payload)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }
  /**to split hybrid benefit as core and flex */
  splitHybridBenefit(selectBenefitList: Array<Benefit>, updateFlexBenefit?: Benefit) {
    let benefitList = selectBenefitList;
    if( updateFlexBenefit ) {
      const benefitIndex = selectBenefitList.findIndex(benefit => benefit.reference === updateFlexBenefit.reference && benefit.pointsPerIncrement && !benefit.coreUnits);
      selectBenefitList[benefitIndex] = {...updateFlexBenefit}
    } else {
      benefitList.forEach((benefit, index) => {
        if(benefit.hybridTempLiving && benefit.coreUnits && benefit.pointsPerIncrement) {
          let coreBenefit = {...benefit};
          
  
          coreBenefit['points'] = 'Guaranteed';
          delete coreBenefit.pointsPerIncrement; 
          delete coreBenefit.rangeIncrementUnit;
          delete coreBenefit.rangeIncrementValue; 
          delete coreBenefit.rangeMax;
          delete coreBenefit.rangeMin;
          delete coreBenefit.selectedIncrementCount
          delete coreBenefit.selectedbenefitCount
          selectBenefitList.push(coreBenefit);
  
          let flexBenefit = {...benefit};
          delete flexBenefit.coreTimeUnit;
          delete flexBenefit.coreUnits;
  
          selectBenefitList[index] = flexBenefit;
          
        } else if(benefit.hybridTempLiving && benefit.pointsPerIncrement && benefit.selectedIncrementCount === 0) {
          selectBenefitList.splice(index,1);
        }
      });
    }
    
    return selectBenefitList;
  }

  mergeHybridBenefit(selectBenefitList: Array<Benefit>): Array<Benefit> {
    selectBenefitList.forEach((benefit, index) => {
      if (benefit.hybridTempLiving && !benefit.coreUnits && benefit.pointsPerIncrement && !benefit.assessNeeds) // finding flex benefit is hybrid and having associated core
      {
        let associatedCoreBen = selectBenefitList.findIndex((coreBen, index) => coreBen.reference === benefit.reference && coreBen.coreUnits);
        if (associatedCoreBen != -1) {
          let coreBenefit = selectBenefitList.find((coreBen, index) => coreBen.reference === benefit.reference && coreBen.coreUnits);
          selectBenefitList.splice(associatedCoreBen, 1);
          delete coreBenefit.points; // delete hard coded points = 'Guaranteed'
          let merge = Object.assign(coreBenefit, benefit);
          const flexIndex = selectBenefitList.findIndex(ben => ben.reference === benefit.reference);
          selectBenefitList[flexIndex] = { ...merge }
        }
      }
    });
    return selectBenefitList;
  }

  getBankInfoData(requestId){
    return this.baseClientService
      .get<any>(`/v1/order-request/${requestId}/bank`,
        '', urlType.integrationApi,true)
      .pipe(
        map(r => r),
        catchError((err, source) => {
          const empty: any = null;
          return of(empty);
        })
      );
  }
  
  updateEstimatedArrivalDate(requestId, obj) {
      return this.baseClientService.put<string>(`/v1/admin/candidate/order-request/${requestId}/estimated-arrival-date`, obj,'', urlType.integrationApi).pipe( 
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to update estimate arrival date' + err);
        return of(empty);
      })
    );
  }

  
}





