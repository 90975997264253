import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from './base-service';
import { ApiConfigService } from './api-config.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandlerService } from './http-error-handler.service';
import { AuthenticationService } from './authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { urlType } from '../models/urlType';
import { isNullOrUndefined } from 'util';
import { AppConfigService } from './app-config.service';

/**
 * Used to make generic standard API calls.  The base URL for the service calls is based on the configuration.
 */
@Injectable({
  providedIn: 'root'
})
export class BaseClientService extends BaseService {
  /** Variable to store the token  */
  private readonly token: UserContext;

  /**
   * base constructor
   * @param config API Config service injector
   * @param http HTTP Client injector
   * @param errorHandler HTTP error handler injector
   */
  constructor(
    config: ApiConfigService,
    http: HttpClient,
    private readonly errorHandler: HttpErrorHandlerService,
    private readonly authsrvc: AuthenticationService,
    private readonly cookieService: CookieService,
    public readonly appConfig : AppConfigService
  ) {
    super(config, http);
  }

  /** Getting logged in user details  */
  public getUserDetails(): string {
    let token;
    this.authsrvc.isAuthenticated().then(res => {

      token = res;
      if (token) { return token.accessToken; }
    });
    return null;
  }
  /** Run a GET API call, expecting a response with a single model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
  getById<TResponseModel>(
    route: string,
    action = 'error executing requests',
    apiUrlType?: urlType
  ): Observable<HttpResponse<TResponseModel>> {
    const url = apiUrlType === urlType.accessmgmt ? this.accessManagementUrl : this.rootUrl;
    return this.http
      .get<TResponseModel>(url + route, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders(),
        withCredentials: true
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a GET API call, expectiing a response with an array of the expected model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected models (array)
   */
  get<TResponseModel>(
    route: string,
    action = 'error executing requests',
    apiUrlType?: urlType,
    isMoveProContext: boolean = false
  ): Observable<HttpResponse<Array<TResponseModel>>> {
    const url = apiUrlType === urlType.accessmgmt ? this.accessManagementUrl : 
              apiUrlType === urlType.integrationApi ? this.apiIntegrationUrl : 
              this.rootUrl;
    return this.http
      .get<TResponseModel>(url + route, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: (apiUrlType === urlType.integrationApi) ?
        this.getRequestHeaderForApiIntegration(isMoveProContext) :
        this.getRequestHeaders(),
        withCredentials: true
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  // getCOL<TResponseModel>(
  //   route: string,
  //   action = 'error executing requests',
  // ): Observable<HttpResponse<Array<TResponseModel>>> {
  //   const url =  urlType.ILAPI ? this.ILAPIUrl : this.rootUrl;
  //   const token = this.cookieService.get('car-ses-tok');
  //   return this.http
  //     .get<TResponseModel>(url + route, {
  //       params: this.newParams(),
  //       observe: 'response',
  //       responseType: 'json',
  //       headers: this.getRequestHeaders(),
  //       withCredentials: true
  //     })
  //     .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  // }

  /** Run a PUT API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being updated
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
  put<TResponseModel>(
    route: string,
    body: any,
    action = 'error putting request',
    apiUrlType?: urlType,
    isMoveProContext: boolean = false
  ): Observable<HttpResponse<TResponseModel>> {
    const url = apiUrlType === urlType.accessmgmt ? this.accessManagementUrl : apiUrlType === urlType.integrationApi ? this.apiIntegrationUrl : this.rootUrl;
    return this.http
      .put<TResponseModel>(url + route, body, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: (apiUrlType === urlType.integrationApi) ?
        this.getRequestHeaderForApiIntegration(isMoveProContext) :
        this.getRequestHeaders(),
        withCredentials: true
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a Patch API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being updated
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
  patch<TResponseModel>(
    route: string,
    body: any,
    action = 'error putting request',
    apiUrlType?: urlType
  ): Observable<HttpResponse<TResponseModel>> {
    const url = apiUrlType === urlType.accessmgmt ? this.accessManagementUrl : this.rootUrl;
    return this.http
      .patch<TResponseModel>(url + route, body, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders(),
        withCredentials: true
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a POST API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being posted
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
  post<TResponseModel>(
    route: string,
    body: any,
    apiUrlType?: urlType,
    isMoveProContext: boolean = false,
    action = 'error posting request'
    ): Observable<HttpResponse<TResponseModel>> {
    const url = apiUrlType === urlType.accessmgmt ? this.accessManagementUrl : apiUrlType === urlType.integrationApi ? this.apiIntegrationUrl : this.rootUrl;
    return this.http
      .post<TResponseModel>(url + route, body, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: (apiUrlType === urlType.integrationApi) ?
        this.getRequestHeaderForApiIntegration(isMoveProContext) :
        this.getRequestHeaders(),
        withCredentials: true
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a DELETE API call
   * @param route The endpoint for the delete request
   * @param action The action that is performing the request
   * @return A response containing the expected result
   */
  delete<TResponseModel>(
    route: string,
    action = 'error delete request',
    apiUrlType?: urlType
  ): Observable<HttpResponse<TResponseModel>> {
    const url = apiUrlType === urlType.accessmgmt ? this.accessManagementUrl : this.rootUrl;

    return this.http
      .delete<TResponseModel>(url + route, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders(),
        withCredentials: true
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a DELETE API call with payload in body
   * @param route The endpoint for the delete request
   * @param action The action that is performing the request
   * @return A response containing the expected result
   */
  bulkDelete < TResponseModel > (route,
      body: Object,
      action = 'error delete request'): Observable < HttpResponse < TResponseModel >> {
      const url = this.rootUrl + route;
      const options = {
          headers: this.getRequestHeaders(),
          withCredentials: true,
          params: this.newParams(),
          body: body
      };
      return this.http.delete < TResponseModel > (url, options)
          .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  private getRequestHeaders(): HttpHeaders {
    // Only send headers which have values to send
    const roles = sessionStorage.getItem('roles');
    const appContext = sessionStorage.getItem('car-ses-con');
    const orderId =  sessionStorage.getItem('car-ses-oid');
    let headers = new HttpHeaders();
    const token = this.cookieService.get('car-ses-tok');
   if (!isNullOrUndefined(orderId) ) {
      headers = new HttpHeaders({
        'Authorization': token,
        'car-ses-oid': sessionStorage.getItem('car-ses-oid')
      });
    } else {
        headers = new HttpHeaders({
          'Authorization': token
        });
      // appContext ? headers.append('appContext', appContext) : null;
    //   orderId ? headers.append('car-ses-oid', orderId) : null;
    }
    return headers;
  }
  private getRequestHeaderForApiIntegration(isMoveProContext): HttpHeaders {
    // Only send headers which have values to send
    const isCoBrowse = this.cookieService.get('transferee-context');
    const appContext = isMoveProContext && isCoBrowse ? this.cookieService.get('app-context') : sessionStorage.getItem('car-ses-con');
    let headers = new HttpHeaders();
    const token = this.cookieService.get('car-ses-tok');
    headers = new HttpHeaders({
      'Authorization': token,
      'app-context': appContext
    });
    return headers;
  }

  /** Run an external GET API callvfor DMS, expecting a response with a single model
   * @param url The url for the request
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
   getDMS(url: string): Observable<any> {
    return this.http.get<any>(this.getDmsSystemURL + url, {
        headers: this.getHeadersForDsm(),
        observe: 'response',
        responseType:'blob' as 'json'
    })
  }

   /**
 * headers for DSM
 * @returns 
 */
  getHeadersForDsm(): HttpHeaders {
    let headers = new HttpHeaders();
    const dmsToken = sessionStorage.getItem('car-ses-dms');
    headers = new HttpHeaders({
      'Authorization' : dmsToken,
      'apiKey': String(this.appConfig.getConfig('API_kEY'))
    });
    return headers;
  }


  /** Run an external GET API callvfor DMS, expecting a response with a single model
   * @param url The url for the request
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
   getLSPaymentData(route: string): Observable<any> {
    const url = this.rootUrl + route;
    return this.http.get<any>(url, {
        headers: this.getRequestHeaders(),
        observe: 'response',
        responseType: 'json',
    })
  }

  dmsPost<TResponseModel>(route: string, body: any, action: string = 'error posting request',
  params: HttpParams = new HttpParams(), isBenefitBuilder: boolean = false): Observable<HttpResponse<TResponseModel>> {  
    const url = this.getDmsSystemURL + route;
    return this.http.post<TResponseModel>(url,
      body,
      {
        headers: this.getHeadersForDsm(),
        withCredentials: false,
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

}

/** It is a class to store user context */
export default interface UserContext {
  /** User Name */
  username: string;
  /** Access Tocken */
  access_token: string;
  /** ID Tocken */
  id_token: string;
  /** User Roles */
  Roles: string[];
  /** Expiry seconds */
  exp: number;
}
