<div *ngIf="!isBenefitsActive" fxLayout fxLayout.xs="column-reverse" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center"
    [ngClass]="!needsAssessmentCompleted?'footer-wrapper linear-background':'footer-wrapper grey-background'" >
    <div  fxFlex="40%" class="footerWrapper">
        <div class="footer-text"><a
            target="_blank"     href="https://submit-irm.trustarc.com/services/validation/0080a1bc-5f59-48fc-993e-83efc1029056">
            {{ 'bb.footer.footerText' | translate }}</a></div>
        <div class="footerlink">
            <ul>
                <li><a tabindex="0" [href]="termsOfUseUrl" target="_blank">{{ 'bb.footer.TermsOfUse' | translate }}</a></li>
                <li><a tabindex="0" [href]="privacyNotice" target="_blank">{{ 'bb.footer.privacyPolicy' | translate }}</a></li>
            </ul>
        </div>
    </div>
    <div class="copyright" [innerHTML]="copyright"></div>
</div>

