import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { Subscription } from 'rxjs';
import { TransfereeNeedsAssessment } from '../../models/candidateneeds-assessment.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewChild } from '@angular/core';
import { PolicyModalComponent } from 'src/app/public/dashboard/components/policy-modal/policy-modal.component';
import { TasksInfoService } from '../../services/tasks-info.service';
import { LocationsService } from '../../../public/needs-assessment/services/locations.service';
import { BenefitService } from 'src/app/public/benefits/services/benefit.service';
import { PersonalInfoService } from '../../services/personal-info.service';
import { FamilyInfoService } from '../../services/family-info.service';
import { CollaborationService } from '../../services/collaboration.service';
import { CollabMessage, CollabEvents } from './../../../core/services/collaboration-events.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from '../../../core/services/language-translation.service';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConsultantInfoModalComponent } from 'src/app/public/lumpsum-dashboard/components/consultant-info-modal/consultant-info-modal.component';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  initials: string;
  latest: any;
  older: any;
  username: string;
  fullName: any;
  onHover: boolean;
  move: any;
  storeMoveData: any;
  isLumpSumMoveOrder: boolean = false;
  showLumpSumHeader: boolean = false;
  // tslint:disable-next-line: no-output-rename
  @Output('openSideNav') openSideNav: EventEmitter<any> = new EventEmitter();
  @Input() isNeedsAssessment;
  /** List of notification items to be displayed */
  @Input() notificationList: Array<{
    header,
    reference,
    message,
    key
  }>;
  /** Flag to open notifications menu */
  @Input() openNotificationMenu: boolean;
  /** Flag to indicate notification menu has been closed */
  @Output() menuClosed: EventEmitter<boolean> = new EventEmitter();
  /** Notification menu trigger */
  @ViewChild('notificationMenuTrigger', {
    static: false
  }) notificationTrigger: MatMenuTrigger;
  listOfNotifications: any = null;
  
	/** Collaboration Subscription */
	collabSubscription: Subscription;
  subsriptionList: Subscription;
  selectedLevel: any;
  tempCountryList: any;
  selectedValue: any;
  browserLanguage: string;
  isConsultant: boolean = false;
  atlasFileNumber: any;


  /**     *
   * @param needsAssessmentShared
   * @param router
   * @param spinner
   * @param tasksService
   * @param toastr
   * @param dialog
   */
  constructor(
    public readonly needsAssessmentShared: NeedsAssessmentSharedService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public readonly tasksService: TasksInfoService,
    public readonly locationService: LocationsService,
    public toastr: MatSnackBar,
    public dialog: MatDialog,
    public cookieService: CookieService,
    public readonly benefitService: BenefitService,
    public familyInfoService : FamilyInfoService,
    public personalInfoService : PersonalInfoService,
    public readonly collaboration: CollaborationService,
    public translate: TranslateService,
    languageTranslationService: LanguageTranslationService
  ) {
    this.browserLanguage = languageTranslationService.getSupportedLanguage();
    translate.use(this.browserLanguage);
  }

  ngOnInit() {
    this.loadHeaderData();
    this.observeCollab();
    this.latest = [];
    this.older = [];
    this.needsAssessmentShared.moveDetails.subscribe(data => {
      
      if (data) {
        this.move = data;
        this.atlasFileNumber = this.move?.orderRequestDetails[0]?.custFileID;  
        this.fullName =  `${this.move.nameDetails.firstName} ${this.move.nameDetails.lastName}`
        this.locationService.countryList.subscribe(countryListData => {
          if (countryListData.length > 0 && this.move.orderRequestDetails.length > 0) {
            this.move.orderRequestDetails.forEach(data => {
              if (data.departureAddr) {
                this.tempCountryList = countryListData.find(ele => ele.countryName === data.departureAddr.country)
                data.departureAddr['countryCode'] = this.tempCountryList.countryiso3CharCode;
              }
              if (data.destinationAddr) {
                this.tempCountryList = countryListData.find(ele => ele.countryName === data.destinationAddr.country)
                data.destinationAddr['countryCode'] = this.tempCountryList.countryiso3CharCode;
              }
            });
          }
        });
        this.older = [];
        for (var i = 0; i < data.orderRequestDetails.length; i++) {
          if (i == 0) {
            this.latest = data.orderRequestDetails[0];
          }
          else {
            this.older.push(data.orderRequestDetails[i]);
          }
        }
      }
    });
    if(this.cookieService.get('transferee-context')){
      this.isConsultant = true;
    }
    this.checkForBudgetDeduction();
    this.needsAssessmentShared.lumpSum.subscribe(data => this.showLumpSumHeader = data);
  }

  checkForBudgetDeduction() {
    this.needsAssessmentShared.transfereeNeedsAssessmentDetails.subscribe(res => {
      if(res) {
        this.isLumpSumMoveOrder = false;
        const needsAssessment: TransfereeNeedsAssessment = res;
        if (needsAssessment.benefitDetails && needsAssessment.benefitDetails.selectionReverted) {
          this.benefitService.budgetReduced.next(true);
        }
        this.latest = res; // updating latest move after switch moves
        if(res?.systemOfOrigin && res?.systemOfOrigin === "Traditional" && res?.hasLumpSum) {
           this.isLumpSumMoveOrder = true;
           this.needsAssessmentShared.lumpSum.next(true);
        }
          
      }
    });
  }

  ngOnChanges() {
    this.listOfNotifications = this.notificationList && this.notificationList.length > 0 ? this.notificationList : [];
    this.openNotificationMenu ? this.notificationTrigger.openMenu() : null;
    if(this.listOfNotifications.length > 0) {
            let index = this.notificationList.findIndex(item => {return item.reference == "policy call"})
            this.tasksService.taskInformation.subscribe(data => {
            if (data && data.policyCall && data.policyCall.status == 'scheduled' && index > -1) {
              this.notificationList.splice(index, 1);
              this.listOfNotifications = this.notificationList && this.notificationList.length > 0 ? this.notificationList : [];
            }
            });
            this.benefitService.contactCardInformation.subscribe(res => {
              if(res){
                      this.listOfNotifications = this.notificationList.filter(ele => ele.key !== res);
             }
          })
          }
  }
  /**
   * To open navigation menu
   */
  openNav() {
    this.openSideNav.emit();
  }
  /**
   * To close navigation menu
   */
  notificationClosed() {
    this.menuClosed.emit(false);
  }

  /**
   * To load header information
   */
  loadHeaderData() {
    this.subsriptionList = this.needsAssessmentShared.transfereeNeedsAssessmentDetails.subscribe(data => {
      if (!data) {
        return;
      }
      const consultantDetails = sessionStorage.getItem('co-browse');
      if (consultantDetails) {
        this.consultantName(JSON.parse(consultantDetails));
      } else {
        const userDetails: TransfereeNeedsAssessment = data;
        //  this.username = `${userDetails.nameDetails.firstName} ${userDetails.nameDetails.lastName}`;
        this.username = `${userDetails.nameDetails.firstName}`;
        this.initials = `${(userDetails.nameDetails.firstName).charAt(0)}${(userDetails.nameDetails.lastName).charAt(0)}`;
      }
    });
  }

  /**
   * To redirect user to appropriate actions by notification
   * @param notificationDetail notification information
   */
  goToNotification(notificationDetail) {
    if (notificationDetail.reference === 'policy call') {
      // To open policy modal component
      this.getCronofyElementsToken(notificationDetail);
    } else if (notificationDetail.reference === 'family info') {
      this.router.navigate(['/profile-info', '1']);
    } else if (notificationDetail.reference === 'tempLiving') {
      this.router.navigate(['/benefits/confirmed/tempLiving']);
    } else {
      this.router.navigate(['/benefits/confirmed/hvb']);
    }
  }

  /**
     * To open notification on key enter pressed
     */

   onEnter(){
    this.notificationTrigger.openMenu();
}

  getCronofyElementsToken(notificationDetail) {

    // Need to check if consultant is assigned or not.
    if (!notificationDetail.consultantDetails) {
      this.toastr.open(this.translate.instant('bb.alertMessages.consultantErrorMessage'), null, {
        duration: 5000
      });
      return;
    }

    this.spinner.show();
    const cronofyId = sessionStorage.getItem('cronofyId');
    this.tasksService.getCronofyToken(cronofyId).subscribe(res => {
      if (res) {
        this.spinner.hide();
        const taskInfo = {
            ...this.tasksService.taskInformation.getValue()
        };
        this.dialog.open(PolicyModalComponent, {
          height: 'auto',
          data: {
            token: res.token,
            cronofyId: cronofyId,
            timezone: notificationDetail.consultantDetails.timeZone,
            policyCallDuration : taskInfo.policyCall.policyCallDuration,
            orderRequestID: this.latest.orderRequestId
          }
        }).afterClosed().subscribe(data => {
          if (data) {
            //this.message = null;
            taskInfo.policyCall.status = 'scheduled';
            taskInfo.family.status = true;
            this.tasksService.taskInformation.next(taskInfo);
          }
        });
      } else {
        this.spinner.hide();
      }
    });
  }

  consultantName(consultantDetails: {
    name: string
  }) {
    const name = consultantDetails.name.split(' ');
    this.username = name[0];
    this.initials = `${(name[0]).charAt(0)}${(name[name.length - 1]).charAt(0)}`;
  }

  logout() {
    this.router.navigate(['/logout']);
  }

  // onChange(move){
  //   this.selectedValue = move.departureAddr.state + ' , ' + move.departureAddr.countryCode + ' > ' + move.destinationAddr.state + ' , ' + move.destinationAddr.countryCode;
  //   // this.selectedValue = move.departureAddr.state + "," + move.departureAddr.countryCode > {{move.destinationAddr.state}} , {{move.destinationAddr.countryCode}}

  // }

  ngOnDestroy() {
    // if (this.subsriptionList) this.subsriptionList.unsubscribe();
  }

  getBenefits() {
    this.benefitService.getBenefits(this.browserLanguage).subscribe(data => {
      if (data) {
        this.benefitService.benefitsDataResponse(data);
        data.availableBenefits .sort((a, b) => a.displayName.localeCompare(b.displayName));
        data.availableBenefits = this.benefitService.reOrderAvailableBenefits(data.availableBenefits);

        const guaranteedBenefits = data.selectedBenefits.filter(benefit => benefit.points === 'Guaranteed');
        data.selectedBenefits = guaranteedBenefits.concat(data.selectedBenefits.filter(benefit => benefit.points !== 'Guaranteed'));

        data.confirmedBenefits .sort((a, b) => {
            return new Date(b.confirmedDate).valueOf() - new Date(a.confirmedDate).valueOf();
          });

        const guaranteedConfirmedBenefits = data.confirmedBenefits.filter(benefit => benefit.points === 'Guaranteed');
        data.confirmedBenefits = guaranteedConfirmedBenefits.concat(data.confirmedBenefits.filter(benefit => benefit.points !== 'Guaranteed'));

        const benefits = this.benefitService.benefitsData.getValue() || {};
        this.benefitService.benefitsData.next(Object.assign(benefits, data));

      } else {
        this.openErrorToastr();
      }
    }, () => {
      this.openErrorToastr();
    })
  }

  updatePersonalInfo(data: TransfereeNeedsAssessment) {
    this.personalInfoService.personalInformationDetails.next(data);
}

observeCollab() {
  this.collabSubscription = this.collaboration.socketSubscriber$.subscribe((message: CollabMessage) => {
    if (!message) { return; }

     if (message.data && message.data.event &&
      message.data.event === CollabEvents.MOVE_ORDER_CHANGE) {
        this.getCollabDetails(message.data.data);
    }

  });
}

  getDetails(moveData) {
    // this.storeMoveData = moveData;
    // this.getBenefits();
    this.spinner.show();
    this.older = this.older.filter(ele => ele.orderRequestId !== moveData.orderRequestId);
    this.older.push(this.latest);
    this.latest = moveData;
    this.needsAssessmentShared.moveDetails.subscribe(res => {
      if (res) {
        const matchData = res.orderRequestDetails.filter(ele => ele.orderRequestId === moveData.orderRequestId);
        const index = res.orderRequestDetails.findIndex(ele => ele.orderRequestId === moveData.orderRequestId);
        this.atlasFileNumber = res.orderRequestDetails[index].custFileID;

					this.collaboration.sendMessage({
						data: matchData[0].orderRequestId,
						event: CollabEvents.MOVE_ORDER_CHANGE
					});
          sessionStorage.setItem('car-ses-oid', matchData[0].orderRequestId);

        if (res.orderRequestDetails[index].confirmStatus || this.cookieService.get('transferee-context')) {
          this.needsAssessmentShared.transfereeNeedsAssessmentDetails.next(this.needsAssessmentShared.transformNeedAssessment(index, res));
          setTimeout(() => {
            this.spinner.show();
          }, 100)
          this.familyInfoService.familyInformation.next(res.orderRequestDetails[index].children);
          this.personalInfoService.getPersonalInformationDetails().subscribe(data => {
            if (data) {
              let index = sessionStorage.getItem('car-ses-oid') ?
                data.orderRequestDetails.findIndex(ele => ele.orderRequestId === sessionStorage.getItem('car-ses-oid')) : 0;

              this.updatePersonalInfo(this.needsAssessmentShared.transformNeedAssessment(index, data));
            }
          });
          this.getBenefits();
          if(this.cookieService.get('transferee-context')) {
            this.router.navigate(['/dashboard']);
          }
          this.spinner.hide();
        } else {
          this.older = this.older.filter(ele => ele.orderRequestId !== moveData.orderRequestId);
          this.older.push(this.latest);
          this.latest = moveData;
          this.router.navigate(['/needs-assessment']);
        }
      }
    })
  }

  getCollabDetails(moveData) {
    if(this.router.url != '/needs-assessment') {
    // this.storeMoveData = moveData;
    // this.getBenefits();
    this.older = this.older.filter(ele => ele.orderRequestId !== moveData);
    this.older.push(this.latest);
    //this.latest = this.older.find(ele => ele.orderRequestId == moveData);
    this.needsAssessmentShared.moveDetails.subscribe(res => {
      if (res) {
        const matchData = res.orderRequestDetails.filter(ele => ele.orderRequestId === moveData);
        const index = res.orderRequestDetails.findIndex(ele => ele.orderRequestId === moveData);
        sessionStorage.setItem('car-ses-oid', matchData[0].orderRequestId);
        this.latest = res.orderRequestDetails[index];
        if (res.orderRequestDetails[index].confirmStatus|| this.cookieService.get('transferee-context')) {
          this.needsAssessmentShared.transfereeNeedsAssessmentDetails.next(this.needsAssessmentShared.transformNeedAssessment(index, res));
          this.familyInfoService.familyInformation.next(res.orderRequestDetails[index].children);
          this.personalInfoService.getPersonalInformationDetails().subscribe(data => {
            if (data) {
              let index = sessionStorage.getItem('car-ses-oid') ?
                data.orderRequestDetails.findIndex(ele => ele.orderRequestId === sessionStorage.getItem('car-ses-oid')) : 0;
             this.updatePersonalInfo(this.needsAssessmentShared.transformNeedAssessment(index, data));
            }
          });
          this.getBenefits();
          this.router.navigate(['/dashboard']);
        } else {
          this.older = this.older.filter(ele => ele.orderRequestId !== moveData.orderRequestId);
          this.older.push(this.latest);
          this.latest = moveData;
          this.router.navigate(['/needs-assessment']);
        }
      }
    })
   }
  }

  openErrorToastr() {
		const toaster = this.toastr.open(this.translate.instant('bb.alertMessages.errorFetchingBenefits'), this.translate.instant('bb.alertMessages.tryAgain'), {
			duration: 5000,
		});
		toaster.onAction().subscribe(_ => this.getBenefits());
	}

  gotoDashboard() {
    this.router.navigate(['/dashboard']);
  }

  gotoLumpsumDashboard() {
    this.router.navigate(['/lumpsum-dashboard']);
  }

  openConsultantInfoModal() {
    const dialogRef = this.dialog.open(ConsultantInfoModalComponent, {
      panelClass: 'dialogMainContainer',
      autoFocus: false,
      disableClose: true,
      width: "40%",
      data: {}
    });
  }
}
