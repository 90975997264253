import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLumpSumMoveOrder: boolean;

  constructor(private readonly spinner: NgxSpinnerService,
    private readonly route: Router,
    private readonly needsAssessmentShared: NeedsAssessmentSharedService,
    private cookieService : CookieService
  ) {
  }

  ngOnInit() {
    this.spinner.show();
    this.isLumpSumMoveOrder = false;
    const needsAssessment = this.needsAssessmentShared.transfereeNeedsAssessmentDetails.getValue();
    if (needsAssessment) {
      this.spinner.hide();
      if (needsAssessment.confirmStatus  || this.cookieService.get('transferee-context')) {
        if(needsAssessment?.systemOfOrigin && needsAssessment?.systemOfOrigin === "Traditional" && needsAssessment?.hasLumpSum) {
          this.isLumpSumMoveOrder = true;
        }
        if(this.isLumpSumMoveOrder)
          this.route.navigate(['/lumpsum-dashboard']);
        else
          this.route.navigate(['/dashboard']);
      } else {
        this.route.navigate(['/needs-assessment']);
      }
    } else {
      this.spinner.hide();
      this.route.navigate(['/logout']);
    }
  }
}
