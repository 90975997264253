import { Component } from '@angular/core';
import * as constants from '../../../core/models/constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.scss']
})
export class PrivacyNoticeComponent {
  /**property to print copyright string */
  readonly copyright = constants.copyright;

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Privacy Statement');
  }
}
