import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as constants from '../../../core/models/constants';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [],
  templateUrl: './contact-us.component.html',
  styleUrl: '../privacy-notice/privacy-notice.component.scss'
})
export class ContactUsComponent {
  /**property to print copyright string */
  readonly copyright = constants.copyright;

  constructor(private readonly titleService: Title) {
    this.titleService.setTitle('Contact Us');
  }
}
