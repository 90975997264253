/** stores classes names */
const classNames = ['heading-three margin-bottom', 'text no-margin', 'sub-heading margin-bottom', 'list-type-one no-bottom-margin'];
/** stores privacy policy data */
export const privacyNoticeContent = [
  {
    content: `Cartus®`,
    class: 'heading no-margin',
  },
  {
    content: `PRIVACY STATEMENT`,
    class: 'heading no-margin',
  },
  {
    content: `Last modified 3 January 2020`,
    class: 'heading',
  },
  {
    content: `We at <span class="strong"> Cartus </span> (collectively, “Cartus”, “We”, “Us”, or “Our”) recognize the importance of
      protecting the privacy of personal data (as defined below, "Personal Information") collected by us. This Privacy Statement describes
      how Cartus collects, uses,
      discloses, retains, and transfers Personal Information, in performing relocation services including human resources data transferred
      to us for processing on behalf of our customers, as well as human resources data of our employees. Information obtained and shared
      with you through this web site is our confidential information unless expressly indicated otherwise. It also describes your choices
      regarding use, access and correction of your Personal Information. Personal Information may be requested/collected to enable the
      delivery of our services, to operate our business and to make the experience of using this site faster and more effective.`,
    class: 'text'
  },
  {
    content: `<span class="strong">By using this web site, you consent to the terms of this Privacy Statement and to Cartus’ processing of
      Personal Information for the purposes stated above, as well as those explained where Cartus collects Personal Information on the web.
      IF YOU DO NOT AGREE TO THIS PRIVACY STATEMENT, YOU CANNOT USE THE SITE.</span> Should our Privacy Statement change, we intend to take
      every reasonable step to ensure that these changes are brought to your attention by posting all changes prominently on our web site
      for a reasonable period of time.`,
    class: 'text'
  },
  {
    content: `Scope of this Privacy Statement`,
    class: classNames[0]
  },
  {
    content: `This Privacy Statement applies to the following sites only:`,
    class: classNames[1]
  },
  {
    content: `<a class="link-style" href="www.cartus.com">www.cartus.com</a> ,
      <a class="link-style" href="online.cartus.com">online.cartus.com</a> ,
      <a class="link-style" href="www.cartusnetworks.com">www.cartusnetworks.com</a> ,
      <a class="link-style" href="www.cartusvirtualresources.com">www.cartusvirtualresources.com</a> ,
      <a class="link-style" href="www.cartusmoves.com">www.cartusmoves.com</a> ,
      <a class="link-style" href="www.myaffinityservices.com">www.myaffinityservices.com</a> and
      <a class="link-style" href="homerewards.cartus.com">homerewards.cartus.com</a> owned and operated by Cartus (collectively, “Cartus”,
      “We”, “Us”, or “Our”).`,
    class: 'text'
  },
  {
    content: `Each site contains links to third-party web sites. When you click on those links, you will go to a third-party site where
      you will be subject to that site’s privacy statement and we encourage you to read that policy statement. Please be aware that we are
      not responsible for the privacy practices of those other web sites and we expressly disclaim any liability for their actions,
      including actions relating to the use and disclosure of Personal Information by those third parties.`,
    class: 'text'
  },
  {
    content: `Cartus collects Personal Information in order to operate our business of providing relocation, assignment management and
      real estate related services. We may transfer Personal Information to companies, which may include our affiliated companies, and
      others that help us to provide our services.`,
    class: 'text'
  },
  {
    content: `In some cases, Cartus acts solely as a processor of the Personal Information at the direction of our client. In those
      instances, an individual who seeks access to, or who seeks to correct, amend, or delete inaccurate Personal Information should direct
      the query to the client. If such a query is directed to Cartus, we will review with our client and Cartus or the client will respond
      to you within a reasonable timeframe.`,
    class: 'text'
  },
  {
    content: `We will retain Personal Information for as long as needed to provide our services and as necessary to comply with our legal
      obligations, resolve disputes, and enforce our agreements.`,
    class: 'text'
  },
  {
    content: `1. What is Personal Information?`,
    class: classNames[0]
  },
  {
    content: `Personal Information means any information that may directly identify you, any designee you appoint to manage your
      <span class="highlight-purple strong">CartusOnline account</span> or a member of your family, including name, address, mobile or other
      telephone number, e-mail address, government identification number, date of birth, photograph, financial account, or other similar
      data elements that may be
      created or made available in connection with your relocation. We ask that you refrain from providing us with sensitive personal
      information about you or members of your family, such as your ethnicity, religion, health condition, and/or sexual orientation, as
      these data elements are not usually needed for your relocation.`,
    class: 'text'
  },
  {
    content: `2. Collection, Use, Transfer and Protection of Your Personal Information`,
    class: classNames[0]
  },
  {
    content: `2(a). Use of Personal Information`,
    class: classNames[0]
  },
  {
    content: `We process your Personal Information as necessary for Cartus to provide relocation services under the terms of its
      contractual obligations with your employer or membership group consistent with the legitimate interests of your employer or membership
      group (i.e., our corporate or government entity client). If you provide us with sensitive Personal Information, we will process it
      based on your consent. We will collect, use, disclose, retain and transfer Personal Information only for purposes of managing and
      facilitating your move from one location to a new location (the “Relocation Purpose”), including the following to the extent
      authorized by your employer or membership group: (a) arranging for your physical move and the storage of your property; (b) tracking
      and processing expense reimbursements; (c) home sales, purchases, and rentals, including financing arrangements; (d) explaining
      various employer-sponsored benefits; (e) immigration and other customs related purposes; (f) cultural assistance and training; and (g)
      other activities relating to the delivery and improvement of relocation services. Any access to your Personal Information is
      restricted to those individuals that have a need to know to fulfill their job responsibilities. We will not use Personal
      Information for any other purpose incompatible with the Relocation Purpose outlined in this Privacy Statement, unless it
      is required or authorized by laws that apply to Cartus or otherwise authorized by you.`,
    class: 'text'
  },
  {
    content: `Collection of Personal Information`,
    class: classNames[2]
  },
  {
    content: `While most of the Personal Information collected by Cartus is supplied by our clients, our suppliers, and our customers as
      part of the Relocation Purpose, we may, at times, ask you to (or you may voluntarily in
      <span class="highlight-purple strong">CartusOnline</span>) update or confirm a subset of
      that Personal Information on this web site. To do this, you may be asked to register to use this site. Additionally, your Personal
      Information may be shared with our affiliates, and third party suppliers (e.g. home inspectors, moving companies) where necessary to
      support and solely for the Relocation Purpose, which may include making Personal Information available to your employer or membership
      group. In addition, we may collect Personal Information from our suppliers and from client and potential client contacts.
      That Personal Information may be used by Cartus to market and/or provide our services to those clients and potential clients.`,
    class: 'text'
  },
  {
    content: `Transfer of Personal Information`,
    class: classNames[2]
  },
  {
    content: `We will share your Personal Information with our affiliates and with third parties for any legitimate reason related to the
      Relocation Purpose and only in the ways that are described in this Privacy Statement. In addition, your information may be stored and
      secured in databases outside of your home country, including the United States of America. For example, we may disclose Personal
      Information (a) to local subcontractors of Cartus who provide relocation services in countries in which Cartus does not have offices;
      (b) to a third party service provider with facilities in India which processes Personal Information for the Relocation Purpose (e.g.,
      freight forwarder invoices may be audited in India); or (c) to governmental authorities, courts, external advisors, or other similar
      parties located in or outside out of your home country. Some of those countries may have different data protection standards than your
      home country. We exercise appropriate due diligence in selecting our third party service providers, and contractually require them to
      treat your Personal Information confidentially, and implement appropriate technical, organizational, and administrative controls to
      safeguard it against unauthorized access, use, or disclosure.`,
    class: 'text'
  },
  {
    content: `We remain responsible for the handling of your Personal Information by those affiliates and third parties as provided in the
      EU - U.S. Privacy Shield Framework Principles and the Swiss-U.S. Privacy Shield Framework Principles, including the Supplemental
      Principles.`,
    class: 'text'
  },
  {
    content: `Cartus may disclose Personal Information about you in special cases when we have reason to believe that disclosing this
      Personal Information is necessary to identify, contact or bring legal action against someone who may be violating Cartus' terms of use
      or terms of service, or may be causing injury to or interference (either intentionally or unintentionally) with any of Cartus' rights
      or property, other Cartus customers, or anyone else. Cartus may disclose or access Personal Information when we believe in good faith
      that the law permits it, as required by law, such as to comply with a subpoena, and for administrative and other purposes that we deem
      necessary to maintain, service, and improve our products and services.`,
    class: 'text'
  },
  {
    content: `Data Retention`,
    class: classNames[0]
  },
  {
    content: `We retain your Personal Information only to the extent necessary to perform contractual obligations and to meet the
      compliance obligations that apply to our multi-jurisdictional business, as reflected in our record retention policy. When we no longer
      need your Personal Information, we will securely remove, deactivate, or de-identify your Personal Information from our systems. If you
      wish to request that we no longer use your Personal Information for the Relocation Purpose, contact us as specified in
      <span class="strong">Contacting
      Us/Correction/Updating Personal Information/Exercising Your Rights.</span> We will retain and use your information as necessary to
      comply with our legal obligations, resolve disputes and enforce our agreements.`,
    class: 'text'
  },
  {
    content: `Registration for Personalized Services`,
    class: classNames[0]
  },
  {
    content: `If you wish to use Cartus’ personalized services, you may be asked to create an account or enroll as a delegate authorized
      by an existing account holder. The process of creating this account or enrolling as a delegate necessitates that you provide Personal
      Information about yourself. If you choose to provide us with your Personal Information on the web, we may transfer that Personal
      Information within Cartus and its affiliates or to Cartus’ third-party service providers, including locations across borders and from
      your country or jurisdiction to other countries or jurisdictions around the world.`,
    class: 'text'
  },
  {
    content: `Cartus strives to comply with all applicable laws worldwide that are designed to protect your privacy. Although legal
      requirements may vary from country to country, Cartus intends to adhere to the principles set forth in this Privacy Statement even if,
      in connection with the above, we transfer your Personal Information from your country to countries that may not require an "adequate"
      level of protection for your Personal Information. In other words, our goal is to provide protection for your Personal Information no
      matter where that Personal Information is collected, transferred, or retained.`,
    class: 'text'
  },
  {
    content: `Requests for Periodic Industry Updates`,
    class: classNames[0]
  },
  {
    content: `If you request Periodic Industry Updates, we collect Personal Information, which is submitted directly to our web hosting
      services vendor’s system to create automatic e-mails to people who choose to subscribe. The information we collect is not used in any
      other way and is not retained once you end your subscription to Periodic Industry Updates. If you would like to discontinue receiving
      this information, you may update your email preferences by using the “Unsubscribe” link found in emails we send to you or
      by contacting us at <a class="link-style" href="InformationProtection@cartus.com">InformationProtection@cartus.com</a> .`,
    class: 'text'
  },
  {
    content: `Requests to Find or List a Property and to Find a Sales Associate`,
    class: classNames[0],
  },
  {
    content: `If you submit a request through this site to find or list a property or to find a real estate agent, you will be asked to
      provide Personal Information and we refer this information to real estate agents to respond to your request. For example, if you ask
      that we list your property or if you ask to see more listings in a given area, you will be directed to a real estate agent in that
      area.`,
    class: 'text'
  },
  {
    content: `Voice Recording`,
    class: classNames[0]
  },
  {
    content: `In addition, telephone calls to or from Cartus in the United States may be recorded. Phone call participants are advised at
      the commencement of services that their participation in telephone calls to or from Cartus, whether or not an announcement is made at
      the time of the call, grants all rights of permission of the participant(s) to Cartus to record calls and utilize such recordings for
      its business purposes. The participant is advised to notify and obtain recording consent from any family members or others who may
      communicate with Cartus as part of a relocation or other parties that participate in conference calls in or request Cartus
      to conference in to any telephone calls with Cartus.`,
    class: 'text'
  },
  {
    content: `2(b). Passive Collection`,
    class: classNames[0]
  },
  {
    content: `As is true of most websites, we gather certain information automatically. This information may include Internet protocol (IP)
      addresses, browser type, Internet service provider (ISP), referring/exit pages, the files viewed on our site (e.g., HTML pages,
      graphics, etc.), operating system, date/time stamp, and/or clickstream data to conduct research, analyze trends in the aggregate for
      internal audits and third-parties, generally to improve our services, and administer the site.`,
    class: 'text'
  },
  {
    content: `Tracking Technologies`,
    class: classNames[0]
  },
  {
    content: `Technologies such as cookies or similar technologies are used by Cartus and our marketing partners and analytics providers.
      These technologies are used in analyzing trends, administering the site, tracking users’ use of the site and to gather demographic
      information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an
      individual as well as aggregated basis.`,
    class: 'text'
  },
  {
    content: `We use cookies for session control and for authentication. Users can control the use of cookies at the individual browser
      level. If you reject cookies, you may still use our site, but your ability to use some features or areas of our site may be limited.`,
    class: 'text'
  },
  {
    content: `Behavioral Targeting / Re-Targeting`,
    class: classNames[0]
  },
  {
    content: `We partner with a third party to display advertising on our website or to manage our advertising on other sites. Our third
      party partner may use cookies or similar technologies in order to provide you advertising based upon your browsing activities and
      interests. If you wish to opt out of interest-based advertising click here [or if located in the European Union click here ].
      Please note you will continue to receive generic ads.`,
    class: 'text'
  },
  {
    content: `2(c). Use of Information With Respect to Third Parties`,
    class: classNames[0]
  },
  {
    content: `We reserve the right to share your Personal Information with our clients, affiliates and third party service providers, as
      described herein, in order to help us deliver our services. As authorized by your employer or membership group we may also share your
      Personal Information with third parties who offer or provide you with other services related to the Relocation Purpose. Those third
      parties (for example, landlords, schools and lenders) will offer and/or enter into direct relationships with you and will not be
      subject to our control. We encourage you to read their privacy policies/statements. Please be aware that we are not responsible
      for the privacy practices of those third parties.`,
    class: 'text'
  },
  {
    content: `Cartus may also disclose Personal Information if Cartus is involved in a merger, acquisition, or sale of all or a portion of
      its assets, in such case, you will be notified via email and/or a prominent notice on our web site of any change in ownership or uses
      of your Personal Information, as well as any choices you may have regarding your Personal Information.`,
    class: 'text'
  },
  {
    content: `Service providers are also an important means by which Cartus maintains its web site and mailing lists. Cartus will take
      reasonable steps to ensure that these third-party service providers are obligated to protect Personal Information on Cartus’ behalf.`,
    class: 'text'
  },
  {
    content: `Cartus does not intend, without your consent, to transfer Personal Information to third parties who are not bound to act on
      Cartus’ behalf unless such transfer is legally required or requested by your employer or membership group as part of the Relocation
      Purpose. Similarly, it is against Cartus’ policy to sell, without your consent, your Personal Information collected online.`,
    class: 'text'
  },
  {
    content: `Social Media Widgets`,
    class: classNames[0]
  },
  {
    content: `Our Web site includes Social Media Features, such as the Facebook Like button and Widgets, such as the Share this button.
      These Features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the Feature to
      function properly. Social Media Features and Widgets are either hosted by a third party or hosted directly on our Site. Your
      interactions with these Features are governed by the privacy statement of the company providing it.`,
    class: 'text'
  },
  {
    content: `3. Location of Servers and Transfer of Information to the United States`,
    class: classNames[0]
  },
  {
    content: `The site is hosted by servers in the United States, and, as such, the site is governed by United States law. If you are
      located outside of the United States, your Personal Information will be transferred to the United States. By submitting your Personal
      Information, you acknowledge our legitimate business interest or consent, as applicable to your jurisdiction, in making the transfer
      to the United States and to its storage, processing, and use there in accordance with this Privacy Statement and United States law.`,
    class: 'text'
  },
  {
    content: `4. Compliance with Privacy Shield and Independent Recourse Mechanism; Federal Trade Commission Jurisdiction`,
    class: classNames[0]
  },
  {
    content: `Cartus complies with the EU-U.S. Privacy Shield Framework and the Swiss – U.S. Privacy Shield Framework as set forth by the
      U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union
      and the United Kingdom and/or Switzerland to the United States, respectively in reliance on Privacy Shield. Cartus has certified to
      the Department of Commerce that it adheres to the Privacy Shield Principles. If there is any conflict between the terms in this
      Privacy Statement and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy
      Shield program, and to view our certification, please visit
      <a class="link-style" href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</a>.`,
    class: 'text'
  },
  {
    content: `Cartus is responsible for the processing of Personal Information it receives, under the Privacy Shield Framework, and
      subsequently transfers to a third party acting as an agent on its behalf. Cartus complies with the Privacy Shield Principles for all
      onward transfers of Personal Information from the EU and Switzerland, including the onward transfer liability provisions.`,
    class: 'text'
  },
  {
    content: `With respect to Personal Information received or transferred pursuant to the Privacy Shield Frameworks, Cartus is subject to
      the regulatory enforcement powers of the U.S. Federal Trade Commission. In certain situations, Cartus may be required to disclose
      Personal Information in response to lawful requests by public authorities, including to meet national security or law enforcement
      requirements.`,
    class: 'text'
  },
  {
    content: `If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based
      third party dispute resolution provider (free of charge) at
      <a class="link-style" href="https://feedback-form.truste.com/watchdog/request">https://feedback-form.truste.com/watchdog/request</a>
      . Where applicable for employee matters, in the context of employment relationship, and employee data that Cartus processes in
      its role as a service provider, Cartus commits to cooperation with the panel of the EU Data Protection Authorities (DPAs),
      the state or national data protection authority where the employee works or the Swiss Federal Data Protection and Information
      Commissioner (FDPIC), as applicable, to investigate unresolved complaints.`,
    class: 'text'
  },
  {
    content: `Under certain conditions, more fully described on the Privacy Shield website
      <a class="link-style" href="https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint">
      https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint</a> ,
      you may be entitled to invoke binding arbitration when other dispute resolution procedures have been exhausted. Once arbitration is
      invoked, in some cases the individual may lose the option to seek relief for the same claimed violation in another forum. For more
      information about binding arbitration please visit:
      <a class="link-style" href="http://www.commerce.gov/privacyshield/">http://www.commerce.gov/privacyshield/</a> .`,
    class: 'text'
  },
  {
    content: `5. Security`,
    class: classNames[0]
  },
  {
    content: `The security of your Personal Information is important to us. Cartus maintains its systems in accordance with reasonable
      industry standards to reasonably secure the Personal Information of its customers against unauthorized access, accidental or
      intentional manipulation, and loss, both during transmission and once it is received. However, no data transmission over the Internet
      can be guaranteed to be 100% secure, and you use the site at your own risk. If you have any questions about the security of your
      personal information, you can contact us using the addresses noted in the “Contact Us” section below.`,
    class: 'text'
  },
  {
    content: `Blogs`,
    class: classNames[0]
  },
  {
    content: `<a class="link-style" href="www.cartus.com/en/blog">www.cartus.com/en/blog</a> offers publicly accessible blogs or community
      forums. You should be aware that any information you provide in these areas may be read, collected, and used by others who access
      them. To request removal of your personal information from our blog or community forum, contact us at
      <a class="link-style" href="InformationProtection@cartus.com">InformationProtection@cartus.com</a> . In some cases, we may not be able
      to remove your personal information, in which case we will let you know if we are unable to do so and why.`,
    class: 'text'
  },
  {
    content: `6. Children`,
    class: classNames[0]
  },
  {
    content: `This site is not directed to children under the age of 13 (“Children”), and we do not knowingly collect any information on
      this site, including Personal Information, from Children.`,
    class: 'text'
  },
  {
    content: `7. Changes in this Privacy Statement`,
    class: classNames[0]
  },
  {
    content: `If we decide to change our Privacy Statement, we will post those changes to this Privacy Statement here, and any other
      places we deem appropriate so that you can be aware of what Personal Information we collect, how we use it, and under what
      circumstances, if any, we disclose it. We reserve the right to modify this Privacy Statement at any time, so please review it
      frequently. If we make material changes to this Statement, we will notify you here or by other means we deem appropriate, prior to the
      change becoming effective.`,
    class: 'text'
  },
  {
    content: `8. Contacting Us/Correction/Updating Personal Information/Exercising Your Rights`,
    class: classNames[0]
  },
  {
    content: `You may contact us through the methods stated below for a number of purposes, including: (1) if you no longer wish to use the
      site; (2) to opt-out of communications or unsubscribe from any industry updates as described in “Requests for Periodic Industry
      Updates”; (3) to receive information or special offers; (4) to provide feedback and comments on this Statement; (5) to request access
      to your Personal Information, deletion or correction of your incorrect Personal Information and/or to confirm if we hold any of your
      Personal Information; and (6) to make inquiries whether we hold or process on behalf of a third party, any of your Personal
      Information or complaints/objections regarding our Privacy Statement and/or privacy practices.`,
    class: 'text'
  },
  {
    content: `By e-mail to: <a class="link-style" href="InformationProtection@cartus.com">InformationProtection@cartus.com</a>`,
    class: 'text'
  },
  {
    content: `By mail: <span class="strong">Privacy Officer</span>, Cartus Corporation, 100 Reserve Road, Danbury, CT 06810;`,
    class: classNames[1]
  },
  {
    content: `Or if your relocation is being handled by our UK affiliate, or you are a`,
    class: classNames[1]
  },
  {
    content: `Cartus employee located in the EU:`,
    class: 'text'
  },
  {
    content: `Director of Legal Services, Cartus Limited, Frankland Road, Blagrove,`,
    class: classNames[1]
  },
  {
    content: `Swindon, Wiltshire SN5 8RS`,
    class: 'text'
  },
  {
    content: `By phone: (203) 205-1540`,
    class: 'text'
  },
  {
    content: `By phone (Singapore): +65 6880 5804`,
    class: 'text'
  },
  {
    content: `We will respond to your request within a reasonable timeframe.`,
    class: 'text'
  },
  {
    content: `In addition, if you are a resident of the EU, you may have the following rights that you should exercise through the contacts
      listed above or directly through your employer or membership group. Cartus will respond to you or aid your employer when you exercise
      the following rights:`,
    class: 'text'
  },
  {
    content: `The right to require a copy of your personal data;`,
    class: classNames[3],
    type: 'list'
  },
  {
    content: `The right to require that inaccurate or out of date personal data be corrected;`,
    class: classNames[3],
    type: 'list'
  },
  {
    content: `The right to request that personal data is erased when it is no longer necessary to be retained;`,
    class: classNames[3],
    type: 'list'
  },
  {
    content: `If consent it the basis for processing, the right to withdraw your consent;`,
    class: classNames[3],
    type: 'list'
  },
  {
    content: `The right to data portability, if applicable;`,
    class: classNames[3],
    type: 'list'
  },
  {
    content: `The right, where there is a dispute in relation to the accuracy or processing of your personal data, to request a ` +
      `restriction be placed on further processing;`,
    class: classNames[3],
    type: 'list'
  },
  {
    content: `The right to object to processing, if applicable; and`,
    class: classNames[3],
    type: 'list'
  },
  {
    content: `You also have the right to lodge a complaint with your applicable data protection authority.`,
    class: classNames[3],
    type: 'list'
  },
];
/** stores privacy policy data */
export const legalTermsContent = [
  {
    content: `Terms of Use/Disclaimer`,
    class: classNames[1],
  },
  {
    content: `What You Should Know`,
    class: classNames[2],
  },
  {
    content: `Welcome to the <span class="orange-highlight"><span class="strong">CartusOnline</span></span> web site (the "Web Site"). The
      goal of this Web Site is to provide you with access to the most
      comprehensive network of relocation-related information, services, and related links to meet your needs. Please read these Terms of
      Use (the "Terms") carefully before continuing on with your use of this Web Site. These Terms shall govern the use of the Web Site and
      apply to all Internet traffic visiting the Web Site. By accessing or using this Web Site, you agree to the Terms. The Terms are meant
      to protect all of our Web Site visitors and your use of this Web Site signifies your agreement with these Terms. IF YOU DO NOT AGREE
      WITH THESE TERMS, DO NOT USE THIS WEB SITE. Cartus Services, Corporation (Cartus) reserves the right, in its sole discretion, to
      modify, alter or otherwise update these Terms at any time. Such modifications shall be effective immediately upon posting. By using
      this Web Site after we have posted notice of such modifications, alterations or updates you agree to be bound by such revised Terms.`,
    class: 'text'
  },
  {
    content: `Telephone calls to or from Cartus may be recorded. Phone call participants are advised that their participation in
      telephone calls to or from Cartus, whether or not an announcement is made at the time of the call, grants all rights of permission of
      the participant(s) to Cartus to record calls and utilize such recordings for its business purposes. Participant should notify and
      obtain recording consent from any family members or others who may communicate with Cartus as part of a relocation or other parties
      that participant conferences in or requests Cartus to conference in to any telephone calls with Cartus.`,
    class: 'text'
  },
  {
    content: `Privacy`,
    class: classNames[2],
  },
  {
    content: `Registration data and certain other information about you is subject to our Privacy Policy. For more information, please
      review our full Privacy Policy.`,
    class: 'text',
  },
  {
    content: `Trademarks, Copyrights and Restrictions`,
    class: classNames[2],
  },
  {
    content: `This Web Site is controlled and operated by Cartus Corporation, 100 Reserve Road, Danbury, CT 06810. All content on this Web Site,
      including, but not limited to, text, images, illustrations, audio clips, and video clips, is protected by copyrights, trademarks,
      service marks, and/or other intellectual property rights (which are governed by U.S. and worldwide copyright laws and treaty
      provisions, privacy and publicity laws, and communication regulations and statutes), and are owned and controlled by Cartus or its
      affiliates, or by third party content providers, merchants, sponsors and licensors (collectively "Providers") that have licensed
      their content or the right to market their products and/or services to Cartus. Cartus and the Cartus logo are pending or registered
      trademarks of Cartus Corporation. Top Block is a registered trademark of USAA. Content on this Web Site or any web site owned,
      operated, licensed or controlled by the Providers is solely for your personal, non-commercial use. You may print a copy of the
      content and/or information contained herein for your personal, non-commercial use only, but you may not copy, reproduce, republish,
      upload, post, transmit, distribute, and/or exploit the content or information in any way (including by e-mail or other electronic
      means) for commercial use without the prior written consent of Cartus or the Providers. You may request consent by faxing a
      request to Legal Affairs at (203) 205-6575 . Without the prior written consent of Cartus or the Providers, your modification of the
      content, use of the content on any other web site or networked computer environment, or use of the content for any purpose other
      than personal, non-commercial use, violates the rights of the owners of the copyrights, trademarks or service marks and other
      proprietary rights, and is prohibited. As a condition to your use of this Web Site, you warrant to Cartus that you will not use this
      Web Site for any purpose that is unlawful or prohibited by these Terms, including without limitation the posting or transmitting any
      threatening, libelous, defamatory, obscene, scandalous, inflammatory, pornographic, or profane material. If you violate any of these
      Terms, your permission to use this Web Site immediately terminates without notice. Cartus retains the right to deny access to anyone
      at its discretion for any reason, including for violation of these Terms. You may not use on your web site any trademarks, service
      marks or copyrighted materials appearing on this Web Site, including, but not limited to, any logos or characters, without the express
      written consent of the owner of the mark or copyright. You may not frame or otherwise incorporate into another web site any of the
      content or other materials on this Web Site without prior written consent of Cartus.`,
    class: 'text',
  },
  {
    content: `Links`,
    class: classNames[2]
  },
  {
    content: `This Web Site may contain links to other web sites ("Linked Sites"). The Linked Sites are provided for your convenience and
      information only and, as such, you access them at your own risk. The content of any Linked Sites is not under Cartus' control, and
      Cartus is not responsible for, and does not endorse, such content, whether or not Cartus is affiliated with the owners of such Linked
      Sites. You may not establish a hyperlink to this Web Site or provide any links that state or imply any sponsorship or endorsement of
      your web site by Cartus, or its affiliates or Providers.`,
    class: 'text'
  },
  {
    content: `Disclaimer of Warranties and Liability`,
    class: classNames[2]
  },
  {
    content: `ALL CONTENT ON THIS WEB SITE IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. OTHER THAN
      THOSE WARRANTIES WHICH, UNDER U.S. LAWS APPLICABLE TO THESE TERMS, ARE IMPLIED BY LAW AND ARE INCAPABLE OF EXCLUSION, RESTRICTION,
      OR MODIFICATION, CARTUS DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
      MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. NEITHER CARTUS ITS AFFILIATED OR RELATED ENTITIES, NOR THE PROVIDERS, NOR ANY
      PERSON INVOLVED IN THE CREATION, PRODUCTION, AND DISTRIBUTION OF THIS WEB SITE WARRANT THAT THE FUNCTIONS CONTAINED IN THIS WEB SITE
      WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVER THAT MAKES THE CONTENT AVAILABLE WILL BE FREE
      OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE CONTENT THAT YOU ACCESS ON THIS WEB SITE IS PROVIDED SOLELY FOR YOUR CONVENIENCE AND
      INFORMATION. CARTUS DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THIS WEB
      SITE, OR AS TO THE RELIABILITY, ACCURACY OR CURRENCY OF ANY INFORMATION CONTENT, SERVICE AND/OR MERCHANDISE ACQUIRED PURSUANT TO YOUR
      USE OF THIS WEB SITE.`,
    class: 'text'
  },
  {
    content: `YOU EXPRESSLY AGREE THAT USE OF THIS WEB SITE IS AT YOUR SOLE RISK. YOU (AND NOT CARTUS) ASSUME THE ENTIRE COST OF ALL
      NECESSARY SERVICING, REPAIR OR CORRECTION OF YOUR SYSTEM. YOU EXPRESSLY AGREE THAT NEITHER CARTUS OR ITS AFFILIATED OR RELATED
      ENTITIES (INCLUDING ITS PROVIDERS), NOR ANY OF THEIR RESPECTIVE EMPLOYEES, OR AGENTS, NOR ANY PERSON OR ENTITY INVOLVED IN THE
      CREATION, PRODUCTION AND DISTRIBUTION OF THIS WEB SITE, IS RESPONSIBLE OR LIABLE TO ANY PERSON OR ENTITY WHATSOEVER FOR ANY LOSS,
      DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE OR OTHERWISE), INJURY, CLAIM, LIABILITY OR OTHER CAUSE OF ANY KIND OR CHARACTER
      WHATSOEVER BASED UPON OR RESULTING FROM THE USE OR ATTEMPTED USE OF THIS WEB SITE OR ANY OTHER LINKED SITE. BY WAY OF EXAMPLE, AND
      WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, CARTUS AND RELATED PERSONS AND ENTITIES SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY
      CLAIM OR DAMAGE ARISING FROM FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION, COMPUTER
      VIRUS, THEFT, DESTRUCTION, UNAUTHORIZED ACCESS TO OR ALTERATION OF PERSONAL RECORDS, OR THE RELIANCE UPON OR USE OF DATA, INFORMATION,
      OPINIONS OR OTHER MATERIALS APPEARING ON THIS WEB SITE. YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT CARTUS IS NOT LIABLE OR RESPONSIBLE
      FOR ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER SUBSCRIBERS OR THIRD PARTIES. SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION
      OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES. IN SUCH JURISDICTIONS, CARTUS' LIABILITY IS LIMITED TO THE
      GREATEST EXTENT PERMITTED BY LAW. YOU FURTHER ACKNOWLEDGE THAT YOU ARE SOLELY RESPONSIBLE FOR ANY MOBILE DEVICE THAT YOU UTILIZE
      TO ACCESS THIS WEB SITE, INCLUDING MANAGING ANY DATA RETAINED OR DOWNLOADED TO THAT DEVICE.`,
    class: 'text'
  },
  {
    content: `Indemnification`,
    class: classNames[2]
  },
  {
    content: `You agree to indemnify, defend, and hold harmless Cartus and the Providers, its and their officers, directors, employees,
      affiliates, agents, licensors, and suppliers from and against all losses, expenses, damages and costs, including reasonable attorneys'
      fees, resulting from any violation by you of these Terms.`,
    class: 'text'
  },
  {
    content: `Jurisdictional Issues`,
    class: classNames[2],
  },
  {
    content: `Unless otherwise specified, the content contained in this Web Site is presented solely for your convenience and/or
      information. This Web Site is controlled and operated by Cartus from its offices in Danbury, Connecticut, USA. Cartus makes no
      representation that content in this Web Site is appropriate or available for use in other locations. Those who choose to access this
      Web Site from other locations do so on their own initiative and are responsible for compliance with local laws, if and to the extent
      local laws are applicable. You may not use or export the materials in this Web Site in violation of U.S. export laws and regulations.
      These Terms shall be governed by, construed and enforced in accordance with the laws of the State of Connecticut as they are applied
      to agreements entered into and to be performed entirely within such State. Any action you, any third party or Cartus brings to enforce
      these Terms, or in connection with any matters related to this Web Site, shall be brought only in either the state or Federal courts
      located in or for Fairfield County, Connecticut, and you expressly consent to the jurisdiction of said courts. If any provision of
      these Terms shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms
      and shall not affect the validity and enforceability of any remaining provisions.`,
    class: 'text',
  },
  {
    content: `Entire Agreement`,
    class: classNames[2]
  },
  {
    content: `The provisions and conditions of these Terms, and each obligation referenced herein, represent the entire Agreement between
      Cartus its affiliated or related entities, and you, and supersede any prior agreements or understandings not incorporated herein. In
      the event that any inconsistencies exist between these Terms and any future published terms of use or understanding, the last
      published Terms or terms of use or understanding shall prevail.`,
    class: 'text'
  },
  {
    content: `ANY RIGHTS NOT EXPRESSLY GRANTED HEREIN ARE RESERVED BY CARTUS.`,
    class: classNames[2]
  }
];

/**used to store tittle for privacy terms*/
export const PRIVACY_TERMS_TEMPLATE_STRING = {
  FOOTER_TXT: `© 2000-${new Date().getFullYear()} Cartus Corporation`
};
