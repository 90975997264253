import { Injectable, Injector } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { TransfereeNeedsAssessment } from '../../core/models/candidateneeds-assessment.model';
import { catchError, map, delay } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';
@Injectable({
    providedIn: 'root'
})
export class PersonalInfoService {

    /** Instance of BehaviorSubject of type TransfereeNeedsAssessment for personal Information*/
    personalInformationDetails = new BehaviorSubject < TransfereeNeedsAssessment > (null);
    /** Personal Info as Promise */
    personalInformation;
    personalInfo = this.personalInformationDetails.asObservable();

    constructor(private readonly baseClientService: BaseClientService, 
        public injector: Injector) {
            this.getPersonalInfoDetails();
    }
    /**
     * Function to Update the personalInformationDetails  subject
     * @param data updated personalInformationDetails
     */
    updatePersonalInfo(data: TransfereeNeedsAssessment) {
        this.personalInformationDetails.next(data);
    }

    /**
     * Function to Get personalInformationDetails and update Shared-Service
     */
    getPersonalInfoDetails() {
        if (!this.personalInformationDetails.getValue() && sessionStorage.getItem('car-ses-oid')) {
            this.getPersonalInformationDetails().subscribe(data => {
                if (data) {
                    let index = sessionStorage.getItem('car-ses-oid') ?
                        data.orderRequestDetails.findIndex(ele => ele.orderRequestId === sessionStorage.getItem('car-ses-oid')) : 0;
                        const needsAssessmentService = this.injector.get(NeedsAssessmentSharedService);
                        this.updatePersonalInfo(needsAssessmentService.transformNeedAssessment( index, data))
                            
                        
                  }
            });
        } else {
            return this.personalInformation = this.personalInformationDetails.getValue();
        }
       
    }

    /**
     * API call to Add personal information data
     */
    addPersonalInformationDetails(
        assessmentDetail: TransfereeNeedsAssessment
    ): Observable < any > {
        return this.baseClientService
            .put < TransfereeNeedsAssessment > (`/v1/profile`, assessmentDetail)
            .pipe(
                map(r => r.body),
                catchError((err, source) => {
                    const empty: TransfereeNeedsAssessment = null;
                    console.log('Failed to add personal details', err);
                    return of(empty);
                })
            );
    }

    /**
     * API call to Get personal information data data
     */
    getPersonalInformationDetails(): Observable < any > {
        return this.baseClientService
            .getById < TransfereeNeedsAssessment > (`/v1/profile`)
            .pipe(
                map(r => r.body),
                catchError(err => {
                    console.log('Failed to get personal details', err);
                    const emptyResp: TransfereeNeedsAssessment = null;
                    return of(emptyResp);
                })
            );
    }
}
