import { Component, OnInit, ViewEncapsulation,Input, OnChanges, SimpleChanges} from '@angular/core';
import * as constants from '../../../core/models/constants';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from '../../../core/services/language-translation.service';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit, OnChanges {
  /**property to print copyright string */
  readonly copyright = constants.copyright;
  browserLanguage: string;

  /**property for which benefit tab selected or not */
  @Input() isBenefitsActive;
  @Input() needsAssessmentCompleted: boolean;
  public termsOfUseUrl : string;
  public privacyNotice : string;


  constructor(translate: TranslateService,
    languageTranslationService: LanguageTranslationService,
    public readonly appConfig : AppConfigService
  ) {
    this.browserLanguage = languageTranslationService.getSupportedLanguage();
    translate.use(this.browserLanguage);
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.needsAssessmentCompleted = changes?.needsAssessmentCompleted?.currentValue;
  }

  ngOnInit() {
    this.termsOfUseUrl = String(this.appConfig.getConfig('termsOfUse'));
    this.privacyNotice = String(this.appConfig.getConfig('privacyPolicy'));
  }

}
