import { Component, OnInit, OnDestroy, ChangeDetectorRef, Output, ViewEncapsulation } from '@angular/core';
import { Subscription, interval, Observable } from "rxjs";
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from '../../services/language-translation.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-toast-idle-timeout',
  templateUrl: './toast-idle-timeout.component.html',
  styleUrls: ['./toast-idle-timeout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToastIdleTimeoutComponent implements OnInit, OnDestroy {
  countDown: Subscription;
  counter: number = 120;
  tick = 1000;  
  timer$: Observable<number>;
  constructor(private ref : ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    public translate: TranslateService,
    private languageTranslationService: LanguageTranslationService) {       
      translate.use(this.languageTranslationService.getSupportedLanguage());
    }

  ngOnInit() {
    this.countDown = interval(this.tick).subscribe (() => {
      --this.counter;
      if(this.counter === 0)
      {
         this._snackBar.dismiss();
      } 
      this.ref.detectChanges();      
    })
    
  }
  ngOnDestroy() {
     this.countDown.unsubscribe();
    this.countDown=null;
  }
}


@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}
