import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-co-browsing-timer',
  templateUrl: './co-browsing-timer.component.html',
  styleUrls: ['./co-browsing-timer.component.scss']
})
export class CoBrowsingTimerComponent implements OnInit {

  counter: number = 5;
  intervalId: any;

  constructor(
    public dialogRef: MatDialogRef<CoBrowsingTimerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  

  ngOnInit() {
    this.intervalId = setInterval(() => {
      this.counter--;
      if (this.counter === 0) {
        this.dialogRef.close(true);
      }
    }, 4000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

}
