<!-- Notification div -->
<!-- <div fxLayout="column" class="notification-container">
<div class = "notification-div" fxLayout="row" role="note" *ngIf='message'>
	<img src="../../../../assets/images/bell-icon.svg" alt="bell with the message" focusable="false" />
	<p>{{message}} <span tabindex="0" class="notification-click" *ngIf="isTransferee" (click)="navigateUserToTask()">Click Here</span></p>
</div>
<div *ngIf = "messagePendingHVB?.length > 0 && isTransferee">
<div class = "notification-div" fxLayout="row" role="note" *ngFor = "let messageHVB of messagePendingHVB">	
	<img src="../../../../assets/images/bell-icon.svg" alt="bell with the message" focusable="false" />
	<p>{{messageHVB.message}} <span tabindex="0" class="notification-click" (click)="navigateUserToBenefit()">Click Here</span></p>
</div>
</div>
</div> -->

<div style=" position: absolute !important;" fxLayout="column" *ngIf="notificationList?.length > 0" class="notification-container">
	<div class = "notification-div" fxLayout="row" role="note" tabindex="0">
		<img src="../../../../assets/images/bell-icon.svg" alt="bell with the message" focusable="false" />
		<p tabindex="0" aria-label="'You have ' + notificationList?.length + 'tasks pending. Please click here' ">{{'bb.notification.part1' | translate}} {{notificationList?.length || 0}} {{'bb.notification.part4' | translate}} {{'bb.notification.part6' | translate}} {{'bb.notification.part3' | translate}} <span tabindex="0" class="notification-click" *ngIf="isTransferee" (keydown.enter)="openNotifications()" (click)="openNotifications()">{{'bb.notification.part2' | translate}}</span></p>
	</div>
