<div class="privacy-notice">
    <div class="page-header">
        <!-- <mat-icon class="logo" svgIcon="logoCartusWhite"></mat-icon> -->
    </div>
    <div class="main-content">
        <ng-container *ngFor="let section of pageContent">
            <ng-container *ngIf="!section.type">
                <div class="{{section.class}}" [innerHtml]="section.content"></div>
            </ng-container>
            <ng-container *ngIf="section.type === 'list'">
                <div class="{{section.class}}">
                    {{section.content}}
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="page-footer">
        {{templateString.FOOTER_TXT}}
    </div>
</div>