import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { FeatureFlagService } from './feature-flag.service';
@Injectable( {
    providedIn: 'root'
} )
export class LanguageTranslationService {
    /** To store list of supported languages */
    supportedLangs: Array < string >;
    /** To store default language */
    defaultLanguage: string;
    /** To store the language in which application will be rendered */
    applicationLanguage = new BehaviorSubject<string>(null);
    /**
     * @param translate Inject Translate Service
     * @param appConfig Inject app config service
     */
    constructor( private translate: TranslateService,
        private readonly appConfig: AppConfigService,
        private readonly featureFlagService: FeatureFlagService ) {
        this.defaultLanguage = this.appConfig.getConfig( 'defaultLanguage' ) as string;
        this.supportedLangs = this.appConfig.getConfig( 'supportedLanguages' ) as Array < string > ;
        translate.setDefaultLang( this.defaultLanguage );
    }
    /**
     * To get the Language set in browser
     * @returns Browser Language
     */
    getBrowserLanguage() {
        return this.translate.getBrowserLang();
    }
    /**
     * Find the supported language based on browser language else default to English
     * @returns Language to be used for application
     */
    getSupportedLanguage() {
          if ( !this.applicationLanguage.getValue() ) {
              if ( this.featureFlagService.isFeatureFlagEnabled( 'bb_is_lang_trans_enabled' ) ) {
                  const browserLang = this.getBrowserLanguage();
                  this.applicationLanguage.next( this.supportedLangs &&  this.supportedLangs.includes( browserLang ) ? browserLang : this.defaultLanguage );
              } else {
                  this.applicationLanguage.next( this.defaultLanguage );
                  return this.defaultLanguage;
              }
          } else {
              return this.applicationLanguage.getValue();
          }
    }
    /**
     * To check if the given language is default language
     * @param language Language 
     * @returns If the given language is default or not
     */
    isDefaultLanguage( language: string ) {
        return language === this.defaultLanguage;
    }
    /**
     * To add targetLanguage parameter to the API URL if language is not default
     * @param url URL
     * @param language browser Language
     * @returns url 
     */
    addURLParamForSupportedLanguage( url: string, language: string ) {
        if ( !this.isDefaultLanguage( language ) ) {
            url += ( url.indexOf( '?' ) === -1 ? '?' : '&' ) + 'targetLang=' + language
        }
        return url;
    }
}
