import { Component, OnInit, Inject, Input } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { TasksInfoService } from 'src/app/core/services/tasks-info.service';
import * as moment from 'moment-timezone';
import { DashboardService } from '../../services/dashboard.service.service';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from '../../../../core/services/language-translation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-policy-modal',
  templateUrl: './policy-modal.component.html',
  styleUrls: ['./policy-modal.component.scss']
})
export class PolicyModalComponent implements OnInit {

  counter = [0];

  readonly hourOffset = this.getHourOffset();
  readonly localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  browserLanguage: string;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PolicyModalComponent>,
    public spinner: NgxSpinnerService,
    public dashboardService: DashboardService,
    public readonly needsAssessmentShared: NeedsAssessmentSharedService,
    public readonly taskInfoService: TasksInfoService,
    public readonly toastr: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    private languageTranslationService: LanguageTranslationService
  ) {
    this.browserLanguage = this.languageTranslationService.getSupportedLanguage()
    translate.use(this.browserLanguage);
  }

  ngOnInit() {
  }

  getHourOffset() {
    const now = moment();
    const localOffset = now.utcOffset();
    now.tz(this.data.timezone);
    const centralOffset = now.utcOffset();
    const diffInHours = (localOffset - centralOffset) / 60;
    return (diffInHours > 0) ? `+${diffInHours}` : diffInHours;
  }

  slotSelectedHandler(data: { slot: { start: string, end: string }, counter: number }) {
    if (!data) { return; }
    this.schedulePolicyCall(data.slot.start, data.slot.end);
  }

  schedulePolicyCall(start: any, end: any) {
    this.spinner.show();
    this.taskInfoService.schedulePolicyCall(start, end).subscribe(res => {
      if (res) {
        this.dashboardService.getUpdatedTimeline(this.data.orderRequestID,this.browserLanguage);
        this.taskInfoService.getTasks().subscribe(data => {
          if (data) {
              this.taskInfoService.updateTaskInfo(data);
          }
      });
        this.spinner.hide();
        this.dialogRef.close(true);
        this.toastr.open(this.translate.instant('bb.alertMessages.thankyouMessage'), null, {
          duration: 5000
        });
      } else {
        this.spinner.hide();
        this.toastr.open(this.translate.instant('bb.alertMessages.policyInvite'), null, {
          duration: 5000
        });
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
