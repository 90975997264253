import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as constants from '../../../core/models/constants';

@Component({
  selector: 'app-terms-of-use',
  standalone: true,
  imports: [],
  templateUrl: './terms-of-use.component.html',
  styleUrl: '../privacy-notice/privacy-notice.component.scss'
})
export class TermsOfUseComponent {
  /**property to print copyright string */
  readonly copyright = constants.copyright;

  constructor(
    private readonly titleService: Title) {

    this.titleService.setTitle('Terms of Use');
  }
}
