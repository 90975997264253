import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TasksInfoService } from './../../services/tasks-info.service';
import { Tasks } from './../../models/tasks.model';
import { CollaborationService } from '../../services/collaboration.service';
import { Subscription } from 'rxjs';
import { Benefit, BenefitService } from './../../../public/benefits/services/benefit.service';
import { NeedsAssessmentSharedService } from 'src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { TransfereeNeedsAssessment } from '../../models/candidateneeds-assessment.model';
import { TranslateService } from '@ngx-translate/core';
import { LanguageTranslationService } from '../../../core/services/language-translation.service';
import { notificationMessage } from './../../models/constants';
import { MatDialog } from '@angular/material/dialog';
export interface notification {
    header: string;
    reference: string;
    message: string;
    key?:string;
    consultantDetails ? : Object;
}
@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})

export class NotificationComponent implements OnInit, OnDestroy {
    /** subscription list for tasks */
    tasksSubscription: Subscription;
    /** message to be displayed */
    message: string;
    /** message to be displayed */
    messagePendingHVB: any[] = [];
    /** link to be redirected to  */
    linkUrl;
    /** To store task details */
    taskDetails: Tasks;
    /** To add respective styles in parent component for notification and pass list of notifications */
    @Output() displayNotification = new EventEmitter();
    /** To open notification section on click of notification bar */
    @Output() openNotification = new EventEmitter();
    /**List of notifications */
    notificationList: Array < notification >= [];
    /**If logged in user is transferee or consultant */
    isTransferee: boolean;
    /** To store list of high value benefits */
    pendingHighValueBenefits: Benefit[];
    /** To store temp living benefits */
    pendingTempLivingBenefit: Benefit[];


    // in order to display in browser selected language
    browserLanguage: string;
    /** Subscription to get task keys */
    notificationKeySubsctiption: Subscription;

    /**     *
     * @param tasksService Inject task service
     * @param benefitService Inject benefit service
     * @param dialog Inject dialog
     * @param collaboration Inject collaboration service
     * @param needsAssessment Inject needs assessment service
     */
    constructor(
        private readonly tasksService: TasksInfoService,
        private readonly benefitService: BenefitService,
        public dialog: MatDialog,
        private collaboration: CollaborationService,
        private needsAssessment: NeedsAssessmentSharedService,
        public translate: TranslateService,
        languageTranslationService: LanguageTranslationService
    ) {
        this.browserLanguage = languageTranslationService.getSupportedLanguage();
        translate.use(this.browserLanguage);
    }


    ngOnInit() {
        this.loadPendingTasks();
        this.getUserType();
        this.checkForBudgetDeduction();

        this.notificationKeySubsctiption = this.tasksService.notificationKey.subscribe((notificationKey) => {
            if(notificationKey !== '') {
                let index = this.notificationList.findIndex(item => {return item.key === notificationKey})
                this.notificationList.splice(index, 1);
                this.tasksService.notificationKey.next('');
                this.displayNotification.emit(this.notificationList);
            }
        })
    }

    /**
     * Load notification for pending tasks
     */
    loadPendingTasks() {
        this.tasksSubscription = this.tasksService.taskInformation.subscribe(tasks => {
            if (!tasks) {
                return;
            }
            this.taskDetails = tasks;
            if (this.taskDetails) {
                // prioritize policy notification
                if (this.taskDetails.policyCall && this.taskDetails.policyCall.status === 'not started') {
                    this.message = this.translate.instant('bb.notification.notificationMessage.policyCall.message');
                    this.notificationList.push({
                        header: this.translate.instant('bb.notification.notificationMessage.policyCall.header'),
                        reference: 'policy call',
                        message: this.message,
                        consultantDetails: this.taskDetails.policyCall.consultantDetails
                    });
                }  else if(this.taskDetails.policyCall && this.taskDetails.policyCall.status === 'scheduled') {
                    let index = this.notificationList.findIndex(item => {return item.reference == "policy call"})
                    this.notificationList.splice(index, 1);

                } else {
                    this.message = null;
                }

                // Save Cronofy Id for Calender Integration
                if (this.taskDetails.policyCall) {
                    const cronofyId = this.taskDetails.policyCall.cronofyId || null;
                    sessionStorage.setItem('cronofyId', cronofyId);
                }
            }
            this.notificationList = this.getPendingFamilyTasks(this.notificationList);
            this.loadPendingHVB(this.notificationList);
            // this.checkForBudgetDeduction();
            this.loadTempLivingContactCard(this.notificationList);
        });
    }

    getPendingFamilyTasks(notificationList) {
        this.needsAssessment.transfereeNeedsAssessmentDetails.subscribe(data => {
            if (!data) {
                return;
            }
            const needsAssessment: TransfereeNeedsAssessment = data;
            this.notificationList = this.notificationList.filter(notification => notification.reference !== 'family info');
            const familyMembersupdated = needsAssessment.children && needsAssessment.children.length > 0 ? needsAssessment.children.length + 1 : 1;

            if (needsAssessment.totalNumberOfRelocatingMembers <= familyMembersupdated) {
                this.notificationList.length > 0 ? this.displayNotification.emit(this.notificationList) : this.displayNotification.emit(null);
            } else {
                this.notificationList.push(this.translate.instant('bb.notification.notificationMessage.family'));
                this.notificationList.length > 0 ? this.displayNotification.emit(this.notificationList) : null;
            }
        });
        return this.notificationList;
    }

    /**
     * Load notification for pending tasks
     */
    loadPendingHVB(listOfNotifications) {
        this.benefitService.benefitsData$.pipe().subscribe((response) => {

            if (response = {}) {
                return;
            } else if (response) {
                this.notificationList = this.notificationList.filter(notification => notification.key !== 'review-benefit');
                /** commented as per MOV360-2446 ticket*/
                // this.pendingHighValueBenefits = this.benefitService.getPendingHighValueBenefits(response.confirmedBenefits);
                this.pendingTempLivingBenefit = this.benefitService.getTempLivingBenefits(response.confirmedBenefits);
                /** commented as per MOV360-2446 ticket*/
                // if (this.pendingHighValueBenefits.length > 0) {
                //     this.messagePendingHVB = [];
                //     this.pendingHighValueBenefits.forEach((item) => {
                //         // const message = notificationMessage.scheduleBenefit.message + item.displayName + ' benefit.';
                //         const message = this.translate.instant('bb.notification.notificationMessage.scheduleBenefit.message') +' '+  item.displayName +   this.translate.instant('bb.benefitCardModel.benefit');
                //         this.messagePendingHVB.push({
                //             header: this.translate.instant('bb.notification.notificationMessage.scheduleBenefit.header'),
                //             reference: item.reference,
                //             key: "review-benefit",
                //             //reference: 'Review Benefit',
                //             message: message
                //         });
                //     });
                //     this.notificationList = [...this.notificationList, ...this.messagePendingHVB];
                // } else {
                //     this.messagePendingHVB = [];
                // }
                if (this.pendingTempLivingBenefit.length > 0) {
                    let messageTempLiving = [];
                    messageTempLiving =  this.pendingTempLivingBenefit.map(ele => {
                        return {
                            header: this.translate.instant('bb.notification.notificationMessage.tempLivingBenefit.header'),
                            reference: 'tempLiving',
                            message: this.translate.instant('bb.notification.notificationMessage.tempLivingBenefit.message'),
                            key: ele.reference
                        }
                    })
                    this.notificationList = this.notificationList.some(ele => ele.reference === 'tempLiving') ? this.notificationList : [...this.notificationList, ...messageTempLiving];
                }
                this.displayNotification.emit(this.notificationList);
            }
        });

    }

    /**total budget reduced to total_budget < confirmed + selected  */
    checkForBudgetDeduction() {
        this.needsAssessment.transfereeNeedsAssessmentDetails.subscribe(data => {
            if (!data) {
                return;
            }
            const needsAssessment: TransfereeNeedsAssessment = data;
            this.benefitService.benefitsData.subscribe(allBenefits => {
                if (allBenefits) {
                    if (allBenefits.selectedBenefits && (allBenefits.selectedBenefits.length > 0) ) {
                        this.benefitService.budgetReduced.next(null);
                        this.notificationList = this.notificationList.filter(ele => ele.reference !== "budget reduced");
                        this.notificationList.length > 0 ? this.displayNotification.emit(this.notificationList) : null;
                        return  ;
                    }
                }
            });

            this.benefitService.budgetReduced.subscribe(res => {
                if (res && this.notificationList.findIndex(ele => ele.reference === 'budget reduced') === -1) {
                    if (needsAssessment.benefitDetails && needsAssessment.benefitDetails.selectionReverted) {
                        this.notificationList
                        this.notificationList.push({
                            message: "The Selections are automatically deselected as the sum of the points selected and confirmed exceeds the total points allocated",
                            header: " ",
                            reference: "budget reduced"
                        });
                        this.benefitService.budgetReduced.next(true);
                    }
                }
            });
            this.notificationList.length > 0 ? this.displayNotification.emit(this.notificationList) : null;
        });
        return ;
    }
    /**Load Notification for temp living contact card */
    loadTempLivingContactCard(listOfNotifications) {
        this.benefitService.getMyContacts().subscribe(response => {
            let productName;
            if (response = {}) {
                return;
            }
            if (response) {
                let messageContactCard = [];
                response.body.benefits.forEach(item => {
                    productName = item.displayName;
                    item.suplierOrders.forEach(ele => {
                        if (!ele.viewStatus) {
                            messageContactCard.push({
                                header: 'Contact Card',
                                key: ele.id,
                                message: `Your Contacts are now updated for ${productName} - ${ele.orderReference} `
                            })
                            // this.notificationList = [...this.notificationList, ...messageContactCard];
                            // console.log(messageContactCard);
                        }
                    })
                })
                this.benefitService.contactCardInformation.next(messageContactCard);
                this.benefitService.contactCardInformation.subscribe(res => {
                    if(res){
                        if(Array.isArray(res)){
                            this.notificationList = [...this.notificationList, ...res];
                        } else{
                            this.notificationList = this.notificationList.filter(ele => ele.key !== res);
                        }

                    }
                })
                this.displayNotification.emit(this.notificationList);
            }
        })
    }

    /**
     * Decides whether the User is Consultant or Transferee
     */
    getUserType() {
        (this.collaboration.userType() === 'consultant') ?
        this.isTransferee = false: this.isTransferee = true;
    }

    /**
     * To open notification section on click of notfication bar
     */
    openNotifications() {
        this.openNotification.emit(this.notificationList);
    }

    ngOnDestroy(): void {
        if (this.tasksSubscription) {
            this.tasksSubscription.unsubscribe();
        }

        this.notificationKeySubsctiption ? this.notificationKeySubsctiption.unsubscribe() : null;
    }

}
