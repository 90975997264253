import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from '../../services/app-config.service';
import { CollaborationService } from '../../services/collaboration.service';
import { CollabEvents } from '../../services/collaboration-events.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly cookieService: CookieService,
    private readonly appConfig: AppConfigService,
    private readonly collaboration: CollaborationService
  ) { }

  ngOnInit() {

    this.collaboration.sendMessage({
      event: CollabEvents.USER_LOGGED_OUT,
      data: null
    });

    // this.collaboration.close();

    setTimeout(() => {
      this.cookieService.deleteAll();
      this.cookieService.deleteAll(null, '.cartus.com');
      sessionStorage.clear();
      const logoutURL = this.appConfig.getConfig('logout');
      this.router.navigate(['/externalRedirect', { externalUrl: logoutURL }], {
        skipLocationChange: true
      });
    }, 1000);

  }
}
