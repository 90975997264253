import { Injectable } from '@angular/core';
import { TransfereeNeedsAssessment } from '../../../core/models/candidateneeds-assessment.model';
import { BaseClientService } from '../../../core/services/base-client.service';
import { catchError, map, delay } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

// import { LoggerService } from './logger.service';
import { urlType } from '../../../core/models/urlType';
@Injectable({
  providedIn: 'root'
})
export class TransfereeNeedsAssessmentService {
  constructor(private readonly baseClientService: BaseClientService) { }
  /**
   * API call to Add Transferee NeedsAssessment data
   */
  addTransfereeNeedsAssessmentDetails(
    assessmentDetail: TransfereeNeedsAssessment
  ): Observable<any> {
    return this.baseClientService
      .put<TransfereeNeedsAssessment>(`/v1/needsassessment`, assessmentDetail)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          console.log('304 Error', err)
          if(err && err?.message.includes('304')) {
            return of({statusCode: 304, statusText: "Not Modified"});
          } else {
            const empty: TransfereeNeedsAssessment = null;
            return of(empty);
          }
        })
      );
  }

  /**
   * API call to Get Transferee NeedsAssessment data
   */
  getTransfereeNeedsAssessment(): Observable<any> {
    return this.baseClientService
      .getById<any>(`/v1/needsassessment`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          // this.customLogger.error('Failed to get transferee details', err);
          // console.log('Failed to get transferee details', err);
          const emptyResp: TransfereeNeedsAssessment = null;
          return of(emptyResp);
        })
      );
  }
}

