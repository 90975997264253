import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from './app-config.service';
import {NeedsAssessmentSharedService} from './../../public/needs-assessment/services/needs-assessment-shared.service'
import { PersonalInfoService } from './personal-info.service';
import { FeatureFlagService } from './feature-flag.service';
import { FeatureFlag } from '../models/feature-flag.model';
@Injectable()
export class AppInitService { 

    rootUrl: string;
    featureFlagService;

  constructor(public injector: Injector) { }

    init() {
        this.loadGoogleMapApiScript();
        const config = this.injector.get( AppConfigService );
        const cookieService = this.injector.get( CookieService );
        this.featureFlagService = this.injector.get( FeatureFlagService );


        const apiConfig: {[key: string]: string | number
        } = config.getConfig( 'api' );
        this.rootUrl = `${apiConfig.protocol}://${apiConfig.host}`;

        let sharedBBSession = window.location.hash;

        // Most probably a shared session
        if ( sharedBBSession.includes( '#/shared' ) ) {

            sharedBBSession = sharedBBSession.split( '#/shared' )[ 1 ];

            // Delete pre cookies
            cookieService.delete( 'car-ses-oid' );
            cookieService.delete( 'transferee-context' );

            return new Promise( async ( resolve, reject ) => {
                try {
                    await this.initSharedBBSession( sharedBBSession );
                    resolve("resolved");
                } catch ( error ) {
                    reject( this.logout() );
                }
            } );

        } else {
            const sessionCookie: string = cookieService.get( 'car-ses-tok' );
            if ( sessionCookie == null || sessionCookie.length === 0 ) {
                this.logout();
            } else {
                return new Promise( ( resolve, reject ) => {
                    const router = this.injector.get( Router );
                    this.featureFlagService.getFeatureFlags().toPromise().then( ( featureFlags: Array < FeatureFlag > ) => {
                        if ( featureFlags ) {
                            this.featureFlagService.updateFeatureFlagsInformation( featureFlags );
                            const needAssessmentSharedSvc = this.injector.get( NeedsAssessmentSharedService );
                            const personalInfoService = this.injector.get( PersonalInfoService );

                            this.loadNeedsAssessment().then( ( data: any ) => {
                                if ( data ) {
                                    const coBrowseDetails = sessionStorage.getItem( 'co-browse');
                                    let needAssessmentDetails;
                                    if(coBrowseDetails) {
                                        const {orderid} = JSON.parse(coBrowseDetails);
                                        const index = data.orderRequestDetails.findIndex(ele => ele.orderRequestId === orderid);
                                        needAssessmentDetails = needAssessmentSharedSvc.transformNeedAssessment( index, data );
                                    } else {
                                        needAssessmentDetails = needAssessmentSharedSvc.transformNeedAssessment( 0, data );
                                    }
                                    
                                    needAssessmentSharedSvc.moveDetails.next( data );
                                    sessionStorage.setItem( 'needs-assessment', JSON.stringify( needAssessmentDetails ) );
                                    sessionStorage.setItem( 'car-ses-oid', needAssessmentDetails.orderRequestId );
                                    personalInfoService.getPersonalInfoDetails();
                                    const isConsultant = cookieService.get( 'transferee-context' );
                                    resolve("resolved");
                                } else if ( !sessionStorage.getItem( 'car-ses-oid' ) ) {
                                    // Clear stuff.
                                    cookieService.deleteAll( '/', '.cartus.com' );
                                    sessionStorage.clear();
                                    this.logoutContactUs();
                                } else {
                                    this.logout();
                                }
                            } )
                        } else {
                            this.logoutContactUs();
                        }
                    } ).catch( err => this.logoutContactUs() );
                } );
            }
        }


    }

    /**
     * Initiate Shared BB Session
     * @param sessionCode Encrypted Code...
     */
    initSharedBBSession( sessionCode ) {
        return new Promise( ( resolve, reject ) => {
            const http = this.injector.get( HttpClient );
            const cookieService = this.injector.get( CookieService );
            const router = this.injector.get( Router );
            

            http.get( this.rootUrl + '/v1/co-browse' + sessionCode, {
                headers: new HttpHeaders( {
                    'Authorization': cookieService.get( 'car-ses-tok' )
                } ),
                withCredentials: true
            } ).subscribe( data => {

                const decrypted: {
                    name ? : string
                    orderid ? : string
                    consultant ? : string
                    transferee ? : string
                } = data;

                this.getUserCapabilities(decrypted.consultant).then((res : any) => {
                    sessionStorage.setItem('capabilities', JSON.stringify(res.capabilities));

                    // To check if user role is consultant
                    if(res.loginUserRoleCapabilities && res.loginUserRoleCapabilities.length > 0) {
                        let isConsultant = res.loginUserRoleCapabilities.some(role => role.roleName === 'consultant');
                        sessionStorage.setItem('isConsultant', JSON.stringify(isConsultant));
                    }
                })

                sessionStorage.setItem( 'co-browse', JSON.stringify( decrypted ) );

                if ( document.location.hostname === 'localhost' ) {
                    cookieService.set( 'car-ses-oid', decrypted.orderid, null, null, '.localhost', true, 'Lax' );
                    cookieService.set( 'transferee-context', decrypted.transferee, null, null, '.localhost', true, 'Lax' );
                } else {
                    cookieService.set( 'car-ses-oid', decrypted.orderid, null, null, '.cartus.com', true, 'Lax' );
                    cookieService.set( 'transferee-context', decrypted.transferee, null, null, '.cartus.com', true, 'Lax' );
                }
                this.featureFlagService.getFeatureFlags().toPromise().then( ( featureFlags: Array < FeatureFlag > ) => {
                    if ( featureFlags ) {
                        this.featureFlagService.updateFeatureFlagsInformation( featureFlags );
                        // Load NeedsAssessment
                        const needAssessmentSharedSvc = this.injector.get( NeedsAssessmentSharedService );
                        const personalInfoService = this.injector.get( PersonalInfoService );
                        this.loadNeedsAssessment().then( ( data: any ) => {
                            if ( data ) {
                                const index = data.orderRequestDetails.findIndex(ele => ele.orderRequestId === decrypted.orderid);
                                let needAssessmentDetails = needAssessmentSharedSvc.transformNeedAssessment( index, data );
                                needAssessmentSharedSvc.moveDetails.next( data );
                                sessionStorage.setItem( 'needs-assessment', JSON.stringify( needAssessmentDetails ) );
                                sessionStorage.setItem( 'car-ses-oid', needAssessmentDetails.orderRequestId );
                                personalInfoService.getPersonalInfoDetails();
                                //const isConsultant = cookieService.get('transferee-context');

                                //needAssessmentSharedSvc.moveDetails.next(data);
                                //sessionStorage.setItem('needs-assessment', JSON.stringify(data));
                                //sessionStorage.setItem('car-ses-oid', data.orderRequestDetails[0].orderRequestId);
                                resolve( router.navigate( [ '/benefits/list' ] ) );
                                
                            } else {
                                reject( this.logout() );
                            }
                        } )
                    } else {
                        this.logoutContactUs();
                    }
                } ).catch( err => reject( this.logout() ) );

            }, ( err ) => reject( this.logout() ) );
        } );
    }

    loadNeedsAssessment() {
        const http = this.injector.get( HttpClient );
        const cookieService = this.injector.get( CookieService );

        return http.get( this.rootUrl + '/v1/needsassessment', {
            headers: new HttpHeaders( {
                'Authorization': cookieService.get( 'car-ses-tok' )
            } ),
            withCredentials: true
        } ).toPromise();

    }

    logout() {
        const cookieService = this.injector.get( CookieService );

        // Clear stuff.
        cookieService.deleteAll();
        sessionStorage.clear();

        const config = this.injector.get( AppConfigService );
        const logoutURL = config.getConfig( 'logout' );
        const router = this.injector.get( Router );
        router.navigate( [ '/externalRedirect', {
            externalUrl: logoutURL
        } ], {
            skipLocationChange: true
        } );
    }

    logoutContactUs() {
        const config = this.injector.get( AppConfigService );
        const logoutContactUrl = config.getConfig( 'logoutContactUrl' );
        const router = this.injector.get( Router );
        router.navigate( [ '/externalRedirect', {
            externalUrl: logoutContactUrl
        } ], {
            skipLocationChange: true
        } );
    }


    getUserCapabilities(moveProContext) {
        const http = this.injector.get( HttpClient );
        const cookieService = this.injector.get( CookieService );
        const config = this.injector.get( AppConfigService );

        const apiConfig: {[key: string]: string | number
        } = config.getConfig( 'integrationApi' );
        const rootUrl = `${apiConfig.protocol}://${apiConfig.host}`;

        return http.get( rootUrl + '/v1/admin/roles-capabilities', {
            headers: new HttpHeaders( {
                'Authorization': cookieService.get( 'car-ses-tok' ), 
                'app-context': moveProContext
            } ),
            withCredentials: true
        } ).toPromise();
      }

      loadGoogleMapApiScript () {
        const config = this.injector.get( AppConfigService );
        var script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&sensor=false&key='+config.getConfig('GOOGLE_MAPS_API_KEY')+'&libraries=places&callback=LazyMapsAPILoader';
        script.async = true;
        document.head.appendChild(script);
      }
      
      
}
